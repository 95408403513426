import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuditoresService {
  private urlService: string;

  constructor(private http: HttpClient) {
    this.urlService = environment.apiUrl;
  }

  getSelectAuditores() {
    return this.http.get(`${this.urlService}auditores/getSelectAuditores`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSelectContratosComplete() {
    return this.http
      .get(`${this.urlService}auditores/getSelectContratosComplete`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  postEditarContratoAsociadoAuditor(postData: {
    idContrato: number;
    idAuditor: number;
    accion: boolean;
  }) {
    return this.http
      .post(
        `${this.urlService}auditores/postEditarContratoAsociadoAuditor`,
        postData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  postInsertAsignarTodosContratosAuditor(postData: { idAuditor: number }) {
    return this.http
      .post(
        `${this.urlService}auditores/postInsertAsignarTodosContratosAuditor`,
        postData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  postDeleteQuitarTodosContratosAuditor(postData: { idAuditor: number }) {
    return this.http
      .post(
        `${this.urlService}auditores/postDeleteQuitarTodosContratosAuditor`,
        postData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
