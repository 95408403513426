import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { FileService } from 'src/app/shared/services/file.service';
import { GlobalDataService, ICurrencies } from 'src/app/shared/services/global-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService } from 'src/app/shared/services/solpe.service';
import { Location } from '@angular/common';
import { ViewExcelButtonsProps, ExcelSheetProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';
import { PrintButtonProps } from 'src/app/shared/components/print-button/print-button.component';
import * as moment from 'src/assets/plugins/moment/moment';
import { PDF } from 'src/app/shared/functions/pdf';
import { delay } from 'src/app/shared/functions/common';
import { formatCurrency } from 'src/app/shared/functions/common';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {

  totalM: number;
  archivosEPIncurrido: any[];
  esIncurrido: string = "N";
  /** Acciones permitidas para el usuario segun su rol */
  accionesPermitidas: string[] = [];
  accionesPermitidasCorr: string[] = [];
  userId: number;
  rolId: number;
  esEmsa: boolean;
  ExchangeRate: FormControl;

  corrEP: number;
  cabecera = { CONT_CORRELATIVO: 0, EP_CORRELATIVO: '', REAJUSTE: 0, EP_DESCRIPCION: '', EP_PERIODO_DESDE: '', EP_PERIODO_HASTA: '', EP_PERIODO: '', ESDO_EP: 0, ESDO_EP_DESC: '', EXCHAGE_RATE: 1, HES: '', FECHA_PAGO: '', NRO_FACTURA: '', ARCHIVO_FACTURA: '', EP_MONTO_TOTAL: '', EP_MONTO_CLP: '', TASA_CAMBIO: '', EP_NUMERO: '' };
  contrato = { NOMBRE_CONTRATO: '', RUT_PROVEEDOR: '', CODIGO_PROVEEDOR: '', PROVEEDOR: '', TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0, CODIGO_CONTRATO: '', FECHA_INICIO_CONTRATO: '',
  FECHA_TERMINO_CONTRATO: '', MONTO_ACTUALIZADO: 0, MONTO_EP: 0 };
  retencion = { RETENCION_LABORAL: 0, RETENCION_CONTRACTUAL: 0, TIPO_RETENCION: '', TIPO_MONEDA: '' };
  detalleEP: any[] = [];
  checklist: any[] = [];

  nombreChk: string;
  formAgregarArchivo: FormGroup;
  submittedDocumento = false;
  archivos64: any;
  fileName: string;
  type: string;
  archivosSubidos = false;
  formAgregarComentario: FormGroup;
  formAgregarComentarioDistribucion: FormGroup;
  formRechazarDistribucion: FormGroup;
  formRechazarEP: FormGroup;
  comentariosEP: any[];
  submittedComentario = false;
  historial: any[];

  /*+ Distribucion */
  formAgregarDistribucion: FormGroup;
  submittedDistribucion = false;
  articulos: any[];
  zonas: any;
  blancos: any[];
  grafos: any[];
  distribuciones: any[] = [];
  distribucionesComentarios: any[];
  distribucionesGrafos: any[];
  distribucionesCreadas = false;
  totalDetalleEP = 0;
  totalDistribucion = 0;
  totalDetalleMontoEP = 0;
  totalMontoDistribucion = 0;
  totaDistribucionAprobada = 0;
  formModificarDistribucion: FormGroup;
  submittedModDistribucion = false;
  distribucionV1: any[] = [];
  distribucionesGrafosV1: any[] = [];

  montoRestanteContrato = 999999;
  cantidadDisponible: number;
  montoDisponible: number;
  cantidadIngresada: number;
  montoIngresado: number;
  precioUnitario: number;
  precioUnitarioConReajuste: number;

  formHES: FormGroup;
  submittedHES = false;

  formFinanzas: FormGroup;
  submittedFacturacion = false;
  fileNameFactura: string;
  archivos64Factura: any;
  typeFactura: string;
  TotalEP: number;
  TotalAcumuladoAnterior: number;
  TotalAcumuladoActual: number;
  TotalEPActual: number;
  TotalEPSaldo: number;
  TotalReajuste: number;
  idDistribucion: number;
  public contractCurrenci: ICurrencies;
  aComprobante: any[] = [];
  correlativoDistribucion: number = 0;
  downloadingPdf: boolean = false;

  tasas = [];
  tasaCambioDelDia: number;
  tasaActualizada: boolean;
  matrizContratosFiltrarItemsReajuste: any[];

  excelButtonComprobante: ViewExcelButtonsProps = {
    title: 'Comprobante_contable',
    filename: 'Comprobante_contable',
    id: '',
    headers: [
      {
        title: 'Articulo',
        value: 'articulo',
      },
      {
        title: 'Zona',
        value: 'zona',
      },
      {
        title: 'Blanco',
        value: 'blanco',
      },
      {
        title: 'Grafo',
        value: 'grafo',
      },
      {
        title: 'Monto',
        value: 'original'
      },
      {
        title: 'Subtotal',
        value: 'clp',
      }
    ],
    data: []
  }

  excelButtonGrafo: ViewExcelButtonsProps = {
    title: 'Total por grafo',
    filename: 'total_grafo',
    headers: [
      {
        title: 'Codigo',
        value: 'codigo',
      },
      {
        title: 'Grafo',
        value: 'nombre',
      },
      {
        title: 'Responsable',
        value: 'responsable',
      },
      {
        title: 'Monto',
        value: 'monto',
      },
    ],
    data: []
  }

  excelButtonHistorial: ViewExcelButtonsProps = {
    title: 'Historial EP',
    filename: 'historial_ep',
    headers: [
      {
        title: 'Fecha',
        value: 'FECHA_CREACION',
      },
      {
        title: 'Usuario',
        value: 'NOMBRE_USUARIO',
      },
      {
        title: 'Etapa',
        value: 'ESTADO',
      },
      {
        title: 'Estado',
        value: 'VIGENTE',
      },
      {
        title: 'Grafo',
        value: 'GRAFO_INFO',
      }
    ],
    data: []
  }

  infoSheet: ExcelSheetProps = {
    title: 'Comprobante_contable',
    filename: 'Comprobante_contable',
    id: '',
    headers: [
      {
        title: 'CODIGO PROVEEDOR',
        value: 'CODIGO_PROVEEDOR'
      },
      {
        title: 'PROVEEDOR',
        value: 'PROVEEDOR'
      },
      {
        title: 'RUT PROVEEDOR',
        value: 'RUT_PROVEEDOR'
      },
      {
        title: 'COD. CONTRATO',
        value: 'CODIGO_CONTRATO'
      },
      {
        title: 'CONTRATO',
        value: 'NOMBRE_CONTRATO'
      },
      {
        title: 'NUMERO EP',
        value: 'EP_NUMERO'
      },
      {
        title: 'DESCRIPCION EP',
        value: 'EP_DESCRIPCION'
      },
      {
        title: 'PERIODO DEL EP',
        value: 'EP_PERIODO'
      },
      {
        title: 'ADMIN. EMSA',
        value: 'ADMINISTRADOR'
      },
      {
        title: 'ADMIN. EECC',
        value: 'ADMIN_PROVEEDOR'
      },
      {
        title: 'ESTADO EP',
        value: 'ESDO_EP_DESC'
      },
      {
        title: 'MONEDA',
        value: 'TIPO_MONEDA'
      },
      {
        title: 'TASA CAMBIO',
        value: 'TASA_CAMBIO'
      },
      {
        title: 'MONTO EP',
        value: 'EP_MONTO_TOTAL'
      },
      {
        title: 'MONTO EP CLP NETO',
        value: 'EP_MONTO_CLP'
      },
    ],
    data: [],
  }

  PeriodoDesde: FormControl;
  PeriodoHasta: FormControl;
  DescricionEP: FormControl;
  ReajusteEp: FormControl;

  printButtonDistribucion: PrintButtonProps = {
    cssFile: '/assets/css/app/checklist.css',
    title: 'Distribucion',
    breakInside: 'auto',
    variant: 'tag',
    orientation: 'landscape',
  }

  @ViewChild('closeModalAgregarArchivo') closeModalAgregarArchivo: ElementRef;
  @ViewChild('closeModalAgregarDistribucion') closeModalAgregarDistribucion: ElementRef;
  @ViewChild('closeModalModificarDistribucion') closeModalModificarDistribucion: ElementRef;
  @ViewChild('closeModalGeneral') closeModalGeneral: ElementRef;
  @ViewChild('closeModalTasa') closeModalTasa: ElementRef;

  fechaPagoEp: string = '';

  itemizado;
  distribucionCorrelativo: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private estadoPagoService: EstadoPagoService,
    private contratoService: ContratoService,
    private notifyService: NotificationService,
    private addonsService: AddonsService,
    private fileService: FileService,
    private solpeService: SolpeService,
    private authService: AuthService,
    public GlobalDataService: GlobalDataService,
    private location: Location,
  ) {
    this.idDistribucion = 0;
    this.TotalEP = 0;
    this.TotalAcumuladoAnterior = 0;
    this.TotalAcumuladoActual = 0;
    this.TotalEPActual = 0;
    this.TotalEPSaldo = 0;
    this.TotalReajuste = 0;
    this.precioUnitarioConReajuste = 0;
    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
    this.esEmsa = JSON.parse(localStorage.getItem('userData')).esEmsa == 'S' ? true : false;
    let corrMenu = 3;
    this.distribucionCorrelativo = 0;
    this.matrizContratosFiltrarItemsReajuste = [
      575, 
      3710, 
      594, 
      591
    ];

    this.formAgregarComentarioDistribucion = new FormGroup({
      comentariodist: new FormControl(null, Validators.required)
    });
    this.formRechazarDistribucion = new FormGroup({
      comentariodistrechazo: new FormControl(null, Validators.required)
    });
    this.formRechazarEP = new FormGroup({
      comentariodistrechazo: new FormControl(null, Validators.required)
    });
    this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
      let acciones: any[] = JSON.parse(resp);
      if (acciones.length > 0) {
        this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
        this.accionesPermitidasCorr = acciones[0].ACCIONES_CORRELATIVO.split(' - ');
      }
    },
      error => { console.log(error) });

    if (this.GlobalDataService.CurrenciesList.length == 0) {
      this.GlobalDataService.GetCurrencie();
    }

    this.itemizado = [];

    this.ExchangeRate = new FormControl(null);
    this.PeriodoDesde = new FormControl(null);
    this.PeriodoHasta = new FormControl(null);
    this.DescricionEP = new FormControl(null);
    this.ReajusteEp = new FormControl(null);
    this.tasas = [];
    this.tasaCambioDelDia = 0;
    this.tasaActualizada = false;
    this.totalM = 0;

  }
  existeAccion(accion: string) {
    return (this.accionesPermitidasCorr?.includes(accion));
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrEP = params['id_ep'];
      this.getDistribucionV1();
    });
    this.getCabecera();
    this.getRetencion();
    this.getComentarios();
    this.resetFormArchivo();
    this.resetFormComentario();
    this.getHistorial();

    this.resetFormDistribucion();
    this.getArticulos();
    this.getZonas();
    this.getBlancos();
    this.getGrafos();

    this.resetFormHES();
    this.resetFormFacturacion();
    this.resetFormModDist();

    this.getTipoEp();
    this.getArchivosEPIncurrido(this.corrEP);
    this.getComprobante();
  }

  back(): void {
    this.location.back();
  }

  private getTipoEp() {
    this.estadoPagoService.getTipoEp(this.corrEP).subscribe(resp => {
      this.esIncurrido = JSON.parse(resp)[0].ES_INCURRIDO;
    });
  }

  private getCabecera() {
    this.estadoPagoService.getCabeceraEP(this.corrEP).subscribe(resp => {
      this.cabecera = JSON.parse(resp)[0];
      const EXCHANGE_RATE = +this.cabecera.EXCHAGE_RATE;
      this.cabecera.EP_NUMERO = this.cabecera.EP_NUMERO.toString();
      this.cabecera.EP_MONTO_CLP = formatCurrency(+this.cabecera.EP_MONTO_TOTAL * EXCHANGE_RATE, 0);
      this.cabecera.TASA_CAMBIO = formatCurrency(+EXCHANGE_RATE);
      this.tasaCambioDelDia = EXCHANGE_RATE;
      this.ExchangeRate = new FormControl(this.tasaCambioDelDia);

      this.estadoPagoService.getExchageRateTaxes(this.corrEP).subscribe(resp => {
        this.tasas = JSON.parse(resp);
        // this.cabecera.ESDO_EP
        const ultimaActualizacionTasa: any = this.tasas[0];

        if (ultimaActualizacionTasa?.value !== this.tasaCambioDelDia) {
          this.tasaActualizada = false;
        }else{
          this.tasaActualizada = true;
        }
      });

      this.PeriodoDesde = new FormControl(this.cabecera.EP_PERIODO_DESDE);
      this.PeriodoHasta = new FormControl(this.cabecera.EP_PERIODO_HASTA);
      this.DescricionEP = new FormControl(this.cabecera.EP_DESCRIPCION);
      this.ReajusteEp = new FormControl(this.cabecera.REAJUSTE);
      
      if (this.cabecera.FECHA_PAGO !== null) {
        this.fechaPagoEp = moment(this.cabecera.FECHA_PAGO).format('DD/MM/YYYY');
      }
      const EP_PERIODO_DESDE = moment(this.cabecera.EP_PERIODO_DESDE, 'YYYY-MM-DD').format('DD/MM/YYYY');
      const EP_PERIODO_HASTA = moment(this.cabecera.EP_PERIODO_HASTA, 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.cabecera.EP_PERIODO = `Desde: ${EP_PERIODO_DESDE} - Hasta:${EP_PERIODO_HASTA}`;

      // objeto que se envia al excel sin alterar el anterior
      let cabeceraExcelEp = {
        ...this.cabecera,
        EP_MONTO_TOTAL: this.cabecera.EP_MONTO_TOTAL.toString().replace(/\./g, ","),
        TASA_CAMBIO: EXCHANGE_RATE.toString().replace(/\./g, ","),
        EP_MONTO_CLP: (+this.cabecera.EP_MONTO_TOTAL * EXCHANGE_RATE).toString().replace(/\./g, ","),
      }
      
      this.mergeInfoSheet(cabeceraExcelEp);
      this.excelButtonComprobante.id = this.cabecera.EP_CORRELATIVO;
      //this.getDetalle();
      //this.cabecera.REAJUSTE = +this.cabecera.REAJUSTE;
    }, error => console.log(error),
      () => {
        this.getChecklist(this.cabecera.CONT_CORRELATIVO);
        this.getContrato(this.cabecera.CONT_CORRELATIVO);
      });
  }
  private getDetalle() {
    this.estadoPagoService.getDetalleEP(this.corrEP).subscribe(resp => {
      let response = JSON.parse(resp);
      this.TotalAcumuladoAnterior = 0;
      this.TotalEP = 0;
      this.TotalEPActual = 0;
      this.TotalReajuste = 0;
      this.TotalEPSaldo = 0;
      this.TotalAcumuladoActual = 0;

      let idx = 0;
      let cabecera = null;
      this.TotalReajuste = 0;
      let totalMontoReajusteItem: number = 0;

      for (let i in response) {
        if (idx == 0 || response[i]['ITEM_CORRELATIVO'] !== cabecera) {
          this.detalleEP[idx] = {
            Cabecera: response[i]['ITEM_POSICION'] + ' ' + response[i]['ITEM_DESCRIPCION'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            PrecioUnitario: null,
            Total: 0,
            Correlativo: 0,
            R: null,
            Scpq: null,
            CantidadSolicitada: 0,
            CantidadAcumAnt: 0,
            CantidadAcumAct: 0,
            TimoAcumAnt: 0,
            TimoAcumAct: 0,
            Saldo: 0,
            CorrArticulo: 0
          };
          idx++;
          this.detalleEP[idx] = {
            Cabecera: null,
            Subpos: response[i]['ID_SUBPOSICION'],
            Descripcion: response[i]['ID_DESCRIPCION'],
            Unidad: response[i]['ID_UNIDAD'],
            Cantidad: response[i]['ID_CANTIDAD'],
            PrecioUnitario: response[i]['ID_PRECIO_UNIT'],
            Total: 0,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['ID_REAJUSTE'],
            Scpq: response[i]['ID_SCQP'],
            CantidadSolicitada: response[i]['SOLICITA_CANTIDAD'],
            CantidadAcumAnt: response[i]['ACUMULADO_ANTERIOR'],
            CantidadAcumAct: response[i]['ACUMULADO_ANTERIOR'] + response[i]['SOLICITA_CANTIDAD'],
            TimoAcumAnt: response[i]['ACUMULADO_ANTERIOR_TIMO'],
            TimoAcumAct: response[i]['ACUMULADO_ANTERIOR_TIMO'] + (response[i]['ID_PRECIO_UNIT'] * response[i]['SOLICITA_CANTIDAD']),
            Saldo: (response[i]['ID_CANTIDAD'] * response[i]['ID_PRECIO_UNIT']) - (response[i]['ID_PRECIO_UNIT'] * response[i]['SOLICITA_CANTIDAD']),
            CorrArticulo: response[i]['ART_CORRELATIVO'],
            DetCorrelativo: response[i]['DET_CORRELATIVO']
          };

          this.TotalAcumuladoAnterior += (this.detalleEP[idx].TimoAcumAnt);
          var reajuste = ((+this.cabecera.REAJUSTE / 100) + 1);

          const isfiltrarContratoParaItemReajuste: boolean = this.matrizContratosFiltrarItemsReajuste.includes(
            this.cabecera.CONT_CORRELATIVO
          );

          if (isfiltrarContratoParaItemReajuste) {
            if (this.detalleEP[idx].Descripcion == "REAJUSTE") {
              totalMontoReajusteItem += (this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada);
            } 
          }

          if (this.detalleEP[idx].R) {
            this.TotalEP += ((this.detalleEP[idx].Cantidad * this.detalleEP[idx].PrecioUnitario) * (reajuste));
            this.TotalAcumuladoActual += (this.detalleEP[idx].TimoAcumAnt + ((this.detalleEP[idx].CantidadSolicitada * this.detalleEP[idx].PrecioUnitario) * (reajuste)));
            
            if (this.distribucionV1.length > 0) {
              this.TotalEPActual += +((this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada) * (reajuste));
            } else {
              this.TotalEPActual += +((this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada) * (reajuste)).toFixed(this.contractCurrenci?.Decimal);
            }

            console.log(this.distribucionV1.length)
            console.log(this.TotalEPActual)

            this.TotalReajuste += +((this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada) * (reajuste - 1)).toFixed(this.contractCurrenci?.Decimal);
            //alert('tiene reajuste if'+ reajuste);
          }
          else {
            this.TotalEP += (this.detalleEP[idx].Cantidad * this.detalleEP[idx].PrecioUnitario);
            this.TotalAcumuladoActual += (this.detalleEP[idx].TimoAcumAnt + (this.detalleEP[idx].CantidadSolicitada * this.detalleEP[idx].PrecioUnitario));

            if (this.distribucionV1.length > 0) {
              this.TotalEPActual += +(this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada);
            } else {
              this.TotalEPActual += +(this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada).toFixed(this.contractCurrenci?.Decimal);
            }

            console.log(this.distribucionV1.length)
            console.log(this.TotalEPActual)
          }
          //this.TotalEPActual += (this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada);
          this.TotalEPSaldo += (this.detalleEP[idx].Saldo - (this.detalleEP[idx].CantidadAcumAnt * this.detalleEP[idx].PrecioUnitario));

          idx++;
        } else {
          this.detalleEP[idx] = {
            Cabecera: null,
            Subpos: response[i]['ID_SUBPOSICION'],
            Descripcion: response[i]['ID_DESCRIPCION'],
            Unidad: response[i]['ID_UNIDAD'],
            Cantidad: response[i]['ID_CANTIDAD'],
            PrecioUnitario: response[i]['ID_PRECIO_UNIT'],
            Total: 0,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['ID_REAJUSTE'],
            Scpq: response[i]['ID_SCQP'],
            CantidadSolicitada: response[i]['SOLICITA_CANTIDAD'],
            CantidadAcumAnt: response[i]['ACUMULADO_ANTERIOR'],
            CantidadAcumAct: response[i]['ACUMULADO_ANTERIOR'] + response[i]['SOLICITA_CANTIDAD'],
            TimoAcumAnt: response[i]['ACUMULADO_ANTERIOR_TIMO'],
            TimoAcumAct: response[i]['ACUMULADO_ANTERIOR_TIMO'] + (response[i]['ID_PRECIO_UNIT'] * response[i]['SOLICITA_CANTIDAD']),
            Saldo: (response[i]['ID_CANTIDAD'] * response[i]['ID_PRECIO_UNIT']) - (response[i]['ID_PRECIO_UNIT'] * response[i]['SOLICITA_CANTIDAD']),
            CorrArticulo: response[i]['ART_CORRELATIVO'],
            DetCorrelativo: response[i]['DET_CORRELATIVO']
          };
          var reajuste = ((+this.cabecera.REAJUSTE / 100) + 1);

          const isfiltrarContratoParaItemReajuste: boolean = this.matrizContratosFiltrarItemsReajuste.includes(
            this.cabecera.CONT_CORRELATIVO
          );

          if (isfiltrarContratoParaItemReajuste) {
            if (this.detalleEP[idx].Descripcion == "REAJUSTE") {
              totalMontoReajusteItem += (this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada);
            } 
          }

          if (this.detalleEP[idx].R) {
            this.TotalEP += ((this.detalleEP[idx].Cantidad * this.detalleEP[idx].PrecioUnitario) * (reajuste));
            this.TotalAcumuladoActual += (this.detalleEP[idx].TimoAcumAnt + ((this.detalleEP[idx].CantidadSolicitada * this.detalleEP[idx].PrecioUnitario) * (reajuste)));

            if (this.distribucionV1.length > 0) {
              this.TotalEPActual += +((this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada) * (reajuste));
            } else {
              this.TotalEPActual += +((this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada) * (reajuste)).toFixed(this.contractCurrenci?.Decimal);
            }

            console.log(this.distribucionV1.length)
            console.log(this.TotalEPActual)

            this.TotalReajuste += +((this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada) * (reajuste - 1)).toFixed(this.contractCurrenci?.Decimal);
            //alert('tiene reajuste else'+ reajuste);
          }
          else {
            this.TotalEP += (this.detalleEP[idx].Cantidad * this.detalleEP[idx].PrecioUnitario);
            this.TotalAcumuladoActual += (this.detalleEP[idx].TimoAcumAnt + (this.detalleEP[idx].CantidadSolicitada * this.detalleEP[idx].PrecioUnitario));

            if (this.distribucionV1.length > 0) {
              this.TotalEPActual += +(this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada);
            } else {
              this.TotalEPActual += +(this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada).toFixed(this.contractCurrenci?.Decimal);
            }

            console.log(this.distribucionV1.length)
            console.log(this.TotalEPActual)
          }
          //this.TotalEP += (this.detalleEP[idx].Cantidad * this.detalleEP[idx].PrecioUnitario);
          this.TotalAcumuladoAnterior += this.detalleEP[idx].TimoAcumAnt;
          //this.TotalAcumuladoActual += this.detalleEP[idx].TimoAcumAct;
          //this.TotalEPActual += (this.detalleEP[idx].PrecioUnitario * this.detalleEP[idx].CantidadSolicitada);
          this.TotalEPSaldo += this.detalleEP[idx].Saldo;


          idx++;
        }
        cabecera = response[i]['ITEM_CORRELATIVO'];
      }

      this.TotalReajuste = (this.TotalReajuste + totalMontoReajusteItem);

      this.getItemizado();
    }, error => console.log(error),
      () => {
        this.setearDetalles();
        for (let i of this.detalleEP) {
          this.totalDetalleEP += i.CantidadSolicitada;
          this.totalDetalleMontoEP += i.PrecioUnitario * i.CantidadSolicitada;
        }
        this.getDistribucion();

      });
  }
  public calcularTotales()
  {
    this.TotalAcumuladoActual = 0;
    this.TotalEPActual = 0;
    for (let i of this.detalleEP) {
      //this.TotalEP =
      //this.detalleEP[idx].TimoAcumAnt
      this.TotalEPActual +=  +((i.CantidadSolicitada * i.PrecioUnitario) * (((this.cabecera.REAJUSTE/100)*i.R) + 1)).toFixed(this.contractCurrenci?.Decimal);

      if (this.distribucionV1.length > 0) {
        this.TotalEPActual +=  +((i.CantidadSolicitada * i.PrecioUnitario) * (((this.cabecera.REAJUSTE/100)*i.R) + 1));
      } else {
        this.TotalEPActual +=  +((i.CantidadSolicitada * i.PrecioUnitario) * (((this.cabecera.REAJUSTE/100)*i.R) + 1)).toFixed(this.contractCurrenci?.Decimal);
      }
      //this.TotalReajuste = 0;
      this.TotalAcumuladoActual += i.TimoAcumAnt + ((i.CantidadSolicitada * i.PrecioUnitario) * (((this.cabecera.REAJUSTE/100)*i.R) + 1));
    }
    if(parseFloat(this.TotalEPActual.toFixed(this.contractCurrenci?.Decimal)) > (this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_EP))
    {
      this.notifyService.showError('El monto del EP es mayor al saldo restante del contrato', 'Monto del ep');
    }
  }
  public actulizarCantidades(in_det_ep_correlativo: number, in_cantidad: number)
  {
    const postDataFile: { pin_ep_det_correlativo: number, pin_cantidad: number } = { pin_ep_det_correlativo: in_det_ep_correlativo, pin_cantidad: in_cantidad }
    this.estadoPagoService.postUpdateCantidad(postDataFile).subscribe(resp => {
      //this.distribucionesComentarios = JSON.parse(resp);
      //this.getCabecera();
    });
  }
  private getRetencion() {
    this.estadoPagoService.getRetencionEP(this.corrEP).subscribe(resp => {
      this.retencion = JSON.parse(resp)[0];
    });
  }

  onChangeCheckFile(event: any, correlativoFile: number){
    this.estadoPagoService.putUpdateEstadoFileEp(
      {
        checkedFile: event.currentTarget.checked === true ? 1 : 0,
        correlativoFile: correlativoFile
      }
    ).subscribe(resp => {
      this.getChecklist(this.cabecera.CONT_CORRELATIVO);
    });
  }

  private getChecklist(corr_contrato: number) {
    this.estadoPagoService.getChecklistEP(corr_contrato, this.corrEP).subscribe(resp => {
      let response = JSON.parse(resp);

      this.archivosSubidos = true;
      for (let i in response) {
        if (response[i]['ITEMCHK_OBLIGATORIO'] == 'S' && !response[i]['NOMBRE_ARCHIVO'] && !response[i]['ES_EMSA']) {
          this.archivosSubidos = false;
        }
      }

      let idx = 0;
      let cabecera = null;

      for (let i in response) {
        if (idx == 0 || response[i]['GRUPO_NOMBRE'] !== cabecera) {
          this.checklist[idx] = {
            Cabecera: response[i]['GRUPO_NOMBRE'],
            ItemChk: null,
            ItemChkCorrelativo: 0,
            Obligatorio: null,
            NombreArchivo: null,
            checkedFile: null,
            correlativoFile: null,
            es_emsa: response[i]['ES_EMSA']
          };
          idx++;
          this.checklist[idx] = {
            Cabecera: null,
            ItemChk: response[i]['ITEMCHK_NOMBRE'],
            ItemChkCorrelativo: response[i]['ITEMCHK_CORRELATIVO'],
            Obligatorio: response[i]['ITEMCHK_OBLIGATORIO'],
            NombreArchivo: response[i]['NOMBRE_ARCHIVO'],
            checkedFile: response[i]['CHECKED_FILE'],
            correlativoFile: response[i]['CORRELATIVO_FILE'],
            es_emsa: response[i]['ES_EMSA']
          };
          idx++;
        } else {
          this.checklist[idx] = {
            Cabecera: null,
            ItemChk: response[i]['ITEMCHK_NOMBRE'],
            ItemChkCorrelativo: response[i]['ITEMCHK_CORRELATIVO'],
            Obligatorio: response[i]['ITEMCHK_OBLIGATORIO'],
            NombreArchivo: response[i]['NOMBRE_ARCHIVO'],
            checkedFile: response[i]['CHECKED_FILE'],
            correlativoFile: response[i]['CORRELATIVO_FILE'],
            es_emsa: response[i]['ES_EMSA']
          };
          idx++;
        }
        cabecera = response[i]['GRUPO_NOMBRE'];
      }
    });
  }
  private getContrato(corr_contrato: number) {
    this.contratoService.getContratoId(corr_contrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];
      this.contrato.CODIGO_PROVEEDOR = `P${this.contrato.RUT_PROVEEDOR}`;
      this.contractCurrenci = this.GlobalDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.contrato.TIMO_CORRELATIVO);
      // objeto que se envia al excel sin alterar el anterior
      const EXCHANGE_RATE = +this.cabecera.EXCHAGE_RATE;

      let cabeceraExcelEp = {
        ...this.cabecera,
        EP_MONTO_TOTAL: (+this.cabecera.EP_MONTO_TOTAL).toFixed(this.contractCurrenci?.Decimal).replace(/\./g, ","),
        TASA_CAMBIO: EXCHANGE_RATE.toString().replace(/\./g, ","),
        EP_MONTO_CLP: (+this.cabecera.EP_MONTO_TOTAL * EXCHANGE_RATE).toFixed(0).replace(/\./g, ","),
      }

      this.mergeInfoSheet({...cabeceraExcelEp, ...this.contrato});

      this.cabecera.EP_MONTO_TOTAL = formatCurrency(+this.cabecera.EP_MONTO_TOTAL, this.contractCurrenci?.Decimal);
      this.getDetalle();
    });
  }
  openModal(corr_chk: number, nombre_chk: string) {
    this.nombreChk = nombre_chk;
    this.formAgregarArchivo.controls.corr_chk.setValue(corr_chk);
    this.formAgregarArchivo.controls.corr_chk.updateValueAndValidity();
  }
  private resetFormArchivo() {
    this.formAgregarArchivo = new FormGroup({
      adjunto: new FormControl(null, Validators.required),
      corr_chk: new FormControl(null)
    });
    this.submittedDocumento = false;
    this.fileName = null;
  }
  detectFile(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2);
        }
      };
    }
  }
  onAgregarArchivo() {
    this.submittedDocumento = true;
    if (this.formAgregarArchivo.invalid) {
      return;
    }

    if (this.fileName) {
      const postDataFile: { fileName: string, type: string, files: string } = { fileName: this.fileName, type: this.type, files: this.archivos64 }
      this.addonsService.postFileChecklist(postDataFile).subscribe(resp => {
        this.postDocumento(JSON.parse(resp));
      });
    }
    return;
  }
  onAgregarArchivoEPIncurrido() {
    if (this.fileName) {
      const postDataFile: { fileName: string, type: string, files: string } = { fileName: this.fileName, type: this.type, files: this.archivos64 }
      this.addonsService.postFileEPIncurrido(postDataFile).subscribe(resp => {
        this.postDocumentoEPIncurrido(JSON.parse(resp));
      });
    }
    return;
  }
  private postDocumento(nombreArchivo: string) {
    const postData: {
      in_item_chk_correlativo: number,
      iv_nombre_archivo: string,
      in_usua_correlativo_creacion: number,
      in_ep_correlativo: number
    } = {
      in_item_chk_correlativo: this.formAgregarArchivo.value.corr_chk,
      iv_nombre_archivo: nombreArchivo,
      in_usua_correlativo_creacion: 0,
      in_ep_correlativo: this.corrEP
    };

    this.estadoPagoService.registraDocItemChk(postData).subscribe(resp => {
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err => {
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    }, () => {
      this.resetFormArchivo();
      this.getChecklist(this.cabecera.CONT_CORRELATIVO);
      this.closeModalAgregarArchivo.nativeElement.click();
    });
  }
  private postDocumentoEPIncurrido(nombreArchivo: string) {
    const postData: {
      in_ep_correlativo: number,
      iv_nombre_archivo: string,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      iv_nombre_archivo: nombreArchivo,
      in_usua_correlativo_creacion: 0,
    };

    this.estadoPagoService.registraDocEpIncurrido(postData).subscribe(resp => {
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err => {
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    }, () => {

      this.getArchivosEPIncurrido(this.corrEP);
    });
  }
  onCloseArchivo() {
    this.resetFormArchivo();
  }

  private getArchivosEPIncurrido(correlativo_ep: number) {
    //
    this.estadoPagoService.getArchivosEPIncurrido(correlativo_ep).subscribe(resp => {
      this.archivosEPIncurrido = JSON.parse(resp);
    });
  }
  onDescargar(fileName: string) {
    this.fileService.downloadDocumentoChecklist(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err => {
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }

  onDescargarFactura(fileName: string) {
    this.fileService.downloadDocumentoFacturaEp(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err => {
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }

  onDescargarEPIncurrido(fileName: string) {
    this.fileService.downloadDocumentoEPIncurrido(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err => {
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }
  private resetFormComentario() {
    this.formAgregarComentario = new FormGroup({
      comentario: new FormControl(null, Validators.required)
    });
    this.submittedComentario = false;
  }
  private getComentarios() {
    this.estadoPagoService.getComentarios(this.corrEP).subscribe(resp => {
      this.comentariosEP = JSON.parse(resp);
    });
  }
  onAgregarComentario() {
    this.submittedComentario = true;

    if (this.formAgregarComentario.invalid) {
      this.notifyService.showError('Debe ingresa un comentario', 'Crear Comentario');
      return;
    }

    const postData: {
      in_ep_correlativo: number,
      iv_comentario: string,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      iv_comentario: this.formAgregarComentario.value.comentario,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraComentarios(postData).subscribe(resp => {
      this.submittedComentario = false;
      this.notifyService.showSuccess('Comentario creado con exito.', 'Crear Comentario');
    }, err => {
      this.submittedComentario = false;
      this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
      console.log(err);
    }, () => {
      this.getComentarios();
      this.resetFormComentario();
    });
  }
  private getHistorial() {
    this.estadoPagoService.getEstadosEP(this.corrEP).subscribe(resp => {
      this.historial = JSON.parse(resp);
      this.historial = this.historial.map((item) => {
        let status = '';
        if (item.VIGENTE === 'S') {
          status = 'Actual';
        } else if (item.VIGENTE !== 'N') {
          status = item.VIGENTE;
        }
        return {
          ...item,
          VIGENTE: status,
          GRAFO_INFO: `${item.GRAFO} ${item.RESP}`
        }
      })
      this.excelButtonHistorial.data = this.historial;
    });
  }
  avanzarAdminEMSA() {

    setTimeout(() => {
      console.log(this.montoExedido());

      if(this.montoExedido()){
        console.log(this.montoExedido());
        this.notifyService.showWarning('El Monto del Estado de Pago excede el saldo del contrato.', 'Monto de Excedente');
        return;
      }

      if (this.TotalEPActual === 0) {
        this.notifyService.showWarning('El Estado de Pago no puede generarse con un total de 0', 'Estado de Pago');
        return;
      }
  
      const post: {
        in_ep_correlativo: number,
        in_domi_correlativo_estadoep: number,
        in_usua_correlativo_creacion: number
      } = {
        in_ep_correlativo: this.corrEP,
        in_domi_correlativo_estadoep: 101,
        in_usua_correlativo_creacion: 0
      };
  
      this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
        this.notifyService.showSuccess('Etapa avanzada a Pre Aprobación', 'Estado de Pago')
      }, err => {
        this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
        console.log(err)
      }, () => {
        this.getHistorial();
        this.getCabecera();
      });
    }, 700);
  }
  avanzarAPYC() {
    let checkFileIsValid: boolean = true;
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 105,
      in_usua_correlativo_creacion: 0
    };

    for (let index = 0; index < this.checklist.length; index++) {
      const element = this.checklist[index];

      // if (element.Obligatorio === 'S') {

      // }
      if (element.checkedFile !== null) {
        if (element.checkedFile === 0) {
          checkFileIsValid = false;
          break;
        }
      }
    }

    if (checkFileIsValid) {
      this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
        this.notifyService.showSuccess('Etapa avanzada a PYC', 'Estado de Pago')
      }, err => {
        this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
        console.log(err)
      }, () => {
        this.getHistorial();
        this.getCabecera();
      });
    }else{
      this.notifyService.showWarning('Debe verificar que los archivos adjuntos sean validos para continuar con el estado de pago.', 'Estado de Pago');
    }
  }
  avanzarADistribucion() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 102,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showSuccess('Etapa avanzada a Distribucion', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  avanzarARespGrafos() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 103,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showSuccess('Etapa avanzada a Responsables de Grafos', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }

  avanzarARechazadoPorGrafos() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 137,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showWarning('Rechazo por grafos', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }

  avanzarAGestSAP() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 104,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showSuccess('Etapa avanzada a Gestionador SAP', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  avanzarAFacturacion() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 108,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showSuccess('Etapa avanzada a Facturación', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  finalizarEtapa() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 109,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showSuccess('Etapa avanzada desde Facturación', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error avanzar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  rechazaAdminEMSA() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 106,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showWarning('Etapa de Estado de Pago Rechazada', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error al rechazar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  rechazaPYC() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 107,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showWarning('Etapa de Estado de Pago Rechazada', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error al rechazar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  rechazaPYCIncurrido() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 126,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showWarning('Etapa de Estado de Pago Rechazada', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error al rechazar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }
  aprobarEstadoPagoIncurrido() {
    const post: {
      in_ep_correlativo: number,
      in_domi_correlativo_estadoep: number,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_domi_correlativo_estadoep: 125,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.registraEstadoEP(post).subscribe(resp => {
      this.notifyService.showSuccess('Etapa de Estado de Pago Aprobada', 'Estado de Pago')
    }, err => {
      this.notifyService.showError('Error al aprobar etapa', 'Estado de Pago')
      console.log(err)
    }, () => {
      this.getHistorial();
      this.getCabecera();
    })
  }

  /** Distribucion */
  private setearDetalles() {
    this.totalDetalleEP = 0;
    this.totalDistribucion = 0;
    this.totalDetalleMontoEP = 0;
    this.totalMontoDistribucion = 0;
  }
  private resetFormDistribucion() {
    this.formAgregarDistribucion = new FormGroup({
      articulo: new FormControl(null, Validators.required),
      zona: new FormControl(null, Validators.required),
      blanco: new FormControl(null, Validators.required),
      grafo: new FormControl(null, Validators.required),
      cantidad: new FormControl(null),
      monto: new FormControl(null),
      corr_item: new FormControl(null)
    })
    this.submittedDistribucion = false
  }
  private getArticulos() {
    this.solpeService.getArticulosMantenedor().subscribe(resp => {
      this.articulos = JSON.parse(resp)
    })
  }
  private getZonas() {
    this.solpeService.getZonas().subscribe(resp => {
      this.zonas = JSON.parse(resp)
    })
  }
  private getBlancos() {
    this.solpeService.getBlancos().subscribe(resp => {
      this.blancos = JSON.parse(resp)
    })
  }
  private getGrafos() {
    this.solpeService.getGrafos().subscribe(resp => {
      this.grafos = JSON.parse(resp);
      //console.log(this.grafos)
    })
  }
  private getDistribucion(item_correlativo: number = 0) {
    this.estadoPagoService.getDistribucionEP(this.corrEP, this.rolId).subscribe(
      resp => {
      this.distribuciones = JSON.parse(resp);
      }, 
      error => console.log(error),
      () => {
        this.setearDetalles();
        this.totaDistribucionAprobada = 0;
        for (let i of this.detalleEP) {
          this.totalDetalleEP += i.CantidadSolicitada;
          this.totalDetalleMontoEP += i.PrecioUnitario * i.CantidadSolicitada;
        }

        for (let i of this.distribuciones) {
          if (i.EPDI_VIGENTE != 'R') {
            this.totalDistribucion += i.CANTIDAD_ASIGNADA;
            this.totalMontoDistribucion += i.MONTO_ASIGNADO;
          }
          if (i.EPDI_VIGENTE == 'A') {
            this.totaDistribucionAprobada += i.CANTIDAD_ASIGNADA;
          }
          if (item_correlativo != 0 && item_correlativo == i.ITEMCHK_CORRELATIVO) {

            this.estadoPagoService.actualizaEstadoDistribucionEP({ in_dist_ep_correlativo: i.DISTRIBUCION_CORRELATIVO, iv_vigente: 'P' }).subscribe(resp => {
              this.notifyService.showSuccess('Distribucion actualizada con exito.', 'Estado Distribucion');
            }, err => {
              this.notifyService.showError('Error al actualizar Distribucion', 'Estado Distribucion');
              console.log(err);
            }, () => {
              this.getDistribucion();
            });

          }
        }

        const distribucionesGrafos = Array.from(this.groupBy(this.distribuciones, distribuciones => distribuciones.GRAFO_CODIGO));

        const { Decimal } = this.contractCurrenci;

        console.log(this.totalMontoDistribucion.toFixed(Decimal));

        if(this.contrato?.TIPO_MONEDA !== 'CLP') {
          this.excelButtonGrafo.headers.push({
            title: 'Monto CLP',
            value: 'clp'
          })
        }
        this.excelButtonGrafo.headers[3].title = `Monto ${this.contrato?.TIPO_MONEDA}`;

        let total = 0;
        this.distribucionesGrafos = distribucionesGrafos.map(array => {
          const grafo = array[1];
          const cabecera = grafo[0];
          const monto = this.sumTotalGrafo(grafo);
          //const clp = monto * this.cabecera?.EXCHAGE_RATE;
          total += monto;
          // calculas el porcentaje por linea
          const porcentaje = (monto / this.TotalEPActual);
          const totalPorLinea = (porcentaje * this.totalM);
          //console.log(formatCurrency(totalPorLinea, 0));

          return {
            codigo: cabecera?.GRAFO_CODIGO,
            nombre: cabecera?.GRAFO_NOMBRE,
            responsable: cabecera?.RESPONSABLE_GRAFO,
            monto: formatCurrency(monto, Decimal),
            clp: formatCurrency(totalPorLinea, 0)
          }
        });

        const totalClp = total * this.cabecera?.EXCHAGE_RATE;

        this.excelButtonGrafo.data = [
          ...this.distribucionesGrafos,
          {},
          {
            monto: 'TOTAL',
            clp: 'TOTAL CLP',
          },
          {
            monto: formatCurrency(total, Decimal),
            clp: formatCurrency(totalClp, 0),
          }
        ];

        let validacion = this.pendientePorDistribuir();

        if (+validacion.toFixed(this.contractCurrenci.Decimal) == 0) {
          this.distribucionesCreadas = true;
        }else{
          this.distribucionesCreadas = false;
        }
      });
  }
  montoExedido()
  {
    const result = (parseFloat(this.TotalEPActual.toFixed(this.contractCurrenci?.Decimal)) - this.TotalReajuste) > (this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_EP + this.contrato.MONTO_REAJUSTE);
    return result;
  }
  existeDistribucion(itemchk_correlativo: number, cabecera: string) {
    let response = false;
    if (!cabecera) {
      for (let i = 0; i < this.distribuciones.length; i++) {
        if (this.distribuciones[i]['ITEMCHK_CORRELATIVO'] == itemchk_correlativo) {
          response = true;
          return response;
        }
      }
    }

    return response;
  }
  cargarModalDistribucion(item_correlativo: number, cantidad: number, precio: number, corr_articulo: number, tieneRejuste: number) {
    let cantidadTotalDistribucion = 0;
    let montoTotalDistribucion = 0;
    this.precioUnitarioConReajuste = 0;
    this.precioUnitario = precio;


    for (let i = 0; i < this.distribuciones.length; i++) {
      if (this.distribuciones[i]['ITEMCHK_CORRELATIVO'] == item_correlativo && this.distribuciones[i]['EPDI_VIGENTE'] != 'R') {
        cantidadTotalDistribucion += this.distribuciones[i]['CANTIDAD_ASIGNADA'];
        montoTotalDistribucion += this.distribuciones[i]['MONTO_ASIGNADO'];
      }
    }

    let montoTotal = (precio * cantidad);
    let montoPendiente = (montoTotal - montoTotalDistribucion);

    if (tieneRejuste == 1) {
      const reajuste = ((+this.cabecera.REAJUSTE / 100) + 1);
      montoPendiente = (montoTotal * reajuste) - montoTotalDistribucion;

      this.precioUnitarioConReajuste = (precio * reajuste);
      /* this.cantidadDisponible = +(cantidad - totalDistribucion).toFixed(2);
      this.montoDisponible = +(this.precioUnitarioConReajuste * this.cantidadDisponible).toFixed(2); */
      this.cantidadDisponible = +(cantidad - cantidadTotalDistribucion).toFixed(2);
      this.montoDisponible = +montoPendiente.toFixed(this.contractCurrenci.Decimal);
    }
    else {
      this.cantidadDisponible = +(cantidad - cantidadTotalDistribucion).toFixed(2);
      this.montoDisponible = +montoPendiente.toFixed(this.contractCurrenci.Decimal);
    }
    /* this.cantidadDisponible = cantidad - totalDistribucion;
    this.montoDisponible = precio * this.cantidadDisponible; */

    // this.formAgregarDistribucion.controls['cantidad'].setValidators([Validators.required, Validators.max(this.cantidadDisponible)]);
    // this.formAgregarDistribucion.controls['monto'].setValidators([Validators.required, Validators.min(0), Validators.max(this.montoDisponible +100000)]);

    //this.formAgregarDistribucion.controls['cantidad'].setValidators([Validators.required, Validators.max(this.cantidadDisponible)]);

    this.formAgregarDistribucion.controls['articulo'].setValue(corr_articulo);
    this.formAgregarDistribucion.controls['articulo'].disable();
    this.formAgregarDistribucion.controls['articulo'].updateValueAndValidity();
    this.formAgregarDistribucion.controls['cantidad'].setValidators([Validators.required]);
    this.formAgregarDistribucion.controls['monto'].setValidators([Validators.required]);
    this.formAgregarDistribucion.controls['cantidad'].updateValueAndValidity()

    if (precio < 0) {
      this.formAgregarDistribucion.controls['monto'].setValidators([Validators.required]);
    } else {
      this.formAgregarDistribucion.controls['monto'].setValidators([Validators.required, Validators.max(this.montoDisponible +100000)]);
    }

    this.formAgregarDistribucion.controls.corr_item.setValue(item_correlativo);
  }
  changeCantidad() {
    let valor = this.formAgregarDistribucion.value.cantidad;
    //const reajuste =  ((+this.cabecera.REAJUSTE / 100) + 1);
    this.formAgregarDistribucion.controls.monto.setValue((+valor * this.precioUnitario));
    if (this.precioUnitarioConReajuste > 0) {
      this.formAgregarDistribucion.controls.monto.setValue((+valor * this.precioUnitarioConReajuste).toFixed(this.contractCurrenci.Decimal));
      //this.formAgregarDistribucion.controls.monto.setValue((+valor * this.precioUnitarioConReajuste));
    }
    else {
      this.formAgregarDistribucion.controls.monto.setValue((+valor * this.precioUnitario).toFixed(this.contractCurrenci.Decimal));
      //this.formAgregarDistribucion.controls.monto.setValue((+valor * this.precioUnitario));
    }
  }
  changeMonto() {
    let valor = this.formAgregarDistribucion.value.monto;
    //const reajuste =  ((+this.cabecera.REAJUSTE / 100) + 1);
    if (this.precioUnitarioConReajuste > 0) {
      this.formAgregarDistribucion.controls.cantidad.setValue((+valor / this.precioUnitarioConReajuste).toFixed(2));
      //this.formAgregarDistribucion.controls.cantidad.setValue((valor / this.precioUnitarioConReajuste));
    }
    else {
      this.formAgregarDistribucion.controls.cantidad.setValue((+valor / this.precioUnitario).toFixed(2));
      //this.formAgregarDistribucion.controls.cantidad.setValue((valor / this.precioUnitario));
    }
  }
  onAgregarDistribucion() {
    this.submittedDistribucion = true;
    if (this.formAgregarDistribucion.invalid) {
      return;
    }

    // if (this.formAgregarDistribucion.controls['monto'].value <= 0) {
    //   this.notifyService.showWarning('El monto ingresado no puede ser menor o igual a 0', 'Crear Distribucion');
    // } else {
    //   const postData: {
    //     in_ep_correlativo: number,
    //     in_itemchk_correlativo: number,
    //     in_articulo_correlativo: number,
    //     in_zona_correlativo: number,
    //     in_blanco_correlativo: number,
    //     in_grafos_correlativo: number,
    //     in_cantidad_asignado: number,
    //     in_monto_asignado: number,
    //     iv_vigente: string
    //   } = {
    //     in_ep_correlativo: this.corrEP,
    //     in_itemchk_correlativo: this.formAgregarDistribucion.controls['corr_item'].value,
    //     in_articulo_correlativo: this.formAgregarDistribucion.controls['articulo'].value,
    //     in_zona_correlativo: this.formAgregarDistribucion.controls['zona'].value,
    //     in_blanco_correlativo: this.formAgregarDistribucion.controls['blanco'].value,
    //     in_grafos_correlativo: this.formAgregarDistribucion.controls['grafo'].value,
    //     in_cantidad_asignado: this.formAgregarDistribucion.controls['cantidad'].value,
    //     in_monto_asignado: this.formAgregarDistribucion.controls['monto'].value,
    //     iv_vigente: 'P'
    //   };

    //   /* {
    //     in_ep_correlativo: this.corrEP,
    //     in_itemchk_correlativo: this.formAgregarDistribucion.value.corr_item,
    //     in_articulo_correlativo: this.formAgregarDistribucion.value.articulo,
    //     in_zona_correlativo: this.formAgregarDistribucion.value.zona,
    //     in_blanco_correlativo: this.formAgregarDistribucion.value.blanco,
    //     in_grafos_correlativo: this.formAgregarDistribucion.value.grafo,
    //     in_cantidad_asignado: this.formAgregarDistribucion.value.cantidad,
    //     in_monto_asignado: this.formAgregarDistribucion.value.monto,
    //     iv_vigente: 'P'
    //   }; */

    //   this.estadoPagoService.registraDistribucionEP(postData).subscribe(resp => {
    //     this.notifyService.showSuccess('Distribucion creada con exito.', 'Crear Distribucion');
    //   }, err => {
    //     this.notifyService.showError('Error al crear Distribucion', 'Crear Distribucion');
    //     console.log(err);
    //   }, () => {
    //     this.resetFormDistribucion()
    //     this.getDistribucion();
    //     this.closeModalAgregarDistribucion.nativeElement.click();
    //   });
    // }

    const postData: {
      in_ep_correlativo: number,
      in_itemchk_correlativo: number,
      in_articulo_correlativo: number,
      in_zona_correlativo: number,
      in_blanco_correlativo: number,
      in_grafos_correlativo: number,
      in_cantidad_asignado: number,
      in_monto_asignado: number,
      iv_vigente: string
    } = {
      in_ep_correlativo: this.corrEP,
      in_itemchk_correlativo: this.formAgregarDistribucion.controls['corr_item'].value,
      in_articulo_correlativo: this.formAgregarDistribucion.controls['articulo'].value,
      in_zona_correlativo: this.formAgregarDistribucion.controls['zona'].value,
      in_blanco_correlativo: this.formAgregarDistribucion.controls['blanco'].value,
      in_grafos_correlativo: this.formAgregarDistribucion.controls['grafo'].value,
      in_cantidad_asignado: this.formAgregarDistribucion.controls['cantidad'].value,
      in_monto_asignado: this.formAgregarDistribucion.controls['monto'].value,
      iv_vigente: 'P'
    };

    /* {
      in_ep_correlativo: this.corrEP,
      in_itemchk_correlativo: this.formAgregarDistribucion.value.corr_item,
      in_articulo_correlativo: this.formAgregarDistribucion.value.articulo,
      in_zona_correlativo: this.formAgregarDistribucion.value.zona,
      in_blanco_correlativo: this.formAgregarDistribucion.value.blanco,
      in_grafos_correlativo: this.formAgregarDistribucion.value.grafo,
      in_cantidad_asignado: this.formAgregarDistribucion.value.cantidad,
      in_monto_asignado: this.formAgregarDistribucion.value.monto,
      iv_vigente: 'P'
    }; */

    this.estadoPagoService.registraDistribucionEP(postData).subscribe(resp => {
      this.notifyService.showSuccess('Distribucion creada con exito.', 'Crear Distribucion');
    }, err => {
      this.notifyService.showError('Error al crear Distribucion', 'Crear Distribucion');
      console.log(err);
    }, () => {
      this.resetFormDistribucion();
      this.getDistribucion();
      this.getComprobante();
      this.closeModalAgregarDistribucion.nativeElement.click();
    });
  }

  onAceptarDistribucion(corr_distribucion: number) {
    this.distribucionCorrelativo = corr_distribucion;
  }

  aceptarDistribucion() {
    this.estadoPagoService.actualizaEstadoDistribucionEP({ in_dist_ep_correlativo: this.distribucionCorrelativo, iv_vigente: 'A' }).subscribe(resp => {
      this.notifyService.showSuccess('Distribucion actualizada con exito.', 'Estado Distribucion');
    }, err => {
      this.notifyService.showError('Error al actualizar Distribucion', 'Estado Distribucion');
      console.log(err);
    }, () => {
      this.getDistribucion();
      this.getComprobante();
      this.getHistorial();

      /** Si es el ultimo en aceptar la distribucion, se avanza la etapa del EP */
      let distribuciones_all: any[];
      let totaDistribucionAprobada = 0;
      this.estadoPagoService.getDistribucionEP(this.corrEP, 20).subscribe(resp => {
        distribuciones_all = JSON.parse(resp);
      }, error => console.log(error),
        () => {
          for (let i of distribuciones_all) {
            if (i.EPDI_VIGENTE == 'A') {
              totaDistribucionAprobada += i.MONTO_ASIGNADO;
            }
          }
          if (+this.TotalEPActual.toFixed(this.contractCurrenci.Decimal) == +totaDistribucionAprobada.toFixed(this.contractCurrenci.Decimal)) {
            this.probarComprobanteXls();
            this.getHistorial();
          }
        });
    });
  }

  rechazarDistribucion() {
    this.estadoPagoService.actualizaEstadoDistribucionEP({ in_dist_ep_correlativo: this.correlativoDistribucion, iv_vigente: 'R' }).subscribe(resp => {
      this.notifyService.showSuccess('Distribucion actualizada con exito.', 'Estado Distribucion');
      // se regista el estado de rechazo por grafos
      this.avanzarARechazadoPorGrafos();
    }, err => {
      this.notifyService.showError('Error al actualizar Distribucion', 'Estado Distribucion');
      console.log(err);
    }, () => {
      this.getDistribucion();
      this.getComprobante();
      this.getHistorial();
    });
  }

  asignarCorrelativoDistribucion(corr_distribucion: number) {
    this.correlativoDistribucion = corr_distribucion;
    this.getComentariosDistribucion(this.correlativoDistribucion);
  }
  asignarCorrelativoEliminarLineaDistribucion(corr_distribucion: number){
    this.correlativoDistribucion = corr_distribucion;
  }

  eliminarLineaDistribucion(){
    this.estadoPagoService.eliminaLineaDistribucionEP({ correlativo_distribucion: this.correlativoDistribucion }).subscribe(resp => {
      this.notifyService.showSuccess('Distribucion eliminada con exito.', 'Estado Distribucion');
    }, err => {
      this.notifyService.showError('Error al eliminar Distribucion', 'Estado Distribucion');
      console.log(err);
    }, () => {
      this.getDistribucion();
      this.getComprobante();
      this.getHistorial();
    });
  }
  private resetFormModDist() {
    this.formModificarDistribucion = new FormGroup({
      cantidad: new FormControl(null, Validators.required),
      monto: new FormControl(null, Validators.required),
      corr_distribucion: new FormControl(null),
      item_correlativo: new FormControl(null)
    });
    this.submittedModDistribucion = false;
  }

  cargarModalPorDistribucion(corrDistribucion: number, item_correlativo: number, cantidad: number, precio: number, tieneRejuste: number) {
    let cantidadTotalDistribucion = 0;
    let montoTotalDistribucion = 0;
    this.precioUnitarioConReajuste = 0;
    this.precioUnitario = precio;

    for (let i = 0; i < this.distribuciones.length; i++) {
      if (this.distribuciones[i]['ITEMCHK_CORRELATIVO'] == item_correlativo && this.distribuciones[i]['EPDI_VIGENTE'] != 'R' && this.distribuciones[i]['DISTRIBUCION_CORRELATIVO'] != corrDistribucion) {
        cantidadTotalDistribucion += this.distribuciones[i]['CANTIDAD_ASIGNADA'];
        montoTotalDistribucion += this.distribuciones[i]['MONTO_ASIGNADO'];
      }
    }

    // this.cantidadDisponible = +(cantidad - totalDistribucion).toFixed(2);
    // this.montoDisponible = +(precio * this.cantidadDisponible).toFixed(this.contractCurrenci.Decimal);



    this.formModificarDistribucion.controls.corr_distribucion.setValue(corrDistribucion);
    this.formModificarDistribucion.controls.item_correlativo.setValue(item_correlativo);


    // if (tieneRejuste == 1) {
    //   const reajuste = ((+this.cabecera.REAJUSTE / 100) + 1);
    //   this.precioUnitarioConReajuste = (precio * reajuste);
    //   /* this.cantidadDisponible = cantidad - totalDistribucion;
    //   this.montoDisponible = +(this.precioUnitarioConReajuste * this.cantidadDisponible).toFixed(2); */
    //   this.cantidadDisponible = +(cantidad - totalDistribucion).toFixed(2);
    //   //this.montoDisponible = +(this.precioUnitarioConReajuste * this.cantidadDisponible).toFixed(2);
    //   this.montoDisponible = +(this.precioUnitarioConReajuste * this.cantidadDisponible).toFixed(this.contractCurrenci.Decimal);

    //   /* this.cantidadDisponible = +(cantidad - totalDistribucion);
    //   this.montoDisponible = +(this.precioUnitarioConReajuste * this.cantidadDisponible); */
    // }

    let montoTotal = (precio * cantidad);
    let montoPendiente = (montoTotal - montoTotalDistribucion);

    if (tieneRejuste == 1) {
      const reajuste = ((+this.cabecera.REAJUSTE / 100) + 1);
      montoPendiente = (montoTotal * reajuste) - montoTotalDistribucion;

      this.precioUnitarioConReajuste = (precio * reajuste);
      /* this.cantidadDisponible = +(cantidad - totalDistribucion).toFixed(2);
      this.montoDisponible = +(this.precioUnitarioConReajuste * this.cantidadDisponible).toFixed(2); */
      this.cantidadDisponible = +(cantidad - cantidadTotalDistribucion).toFixed(2);
      this.montoDisponible = +montoPendiente.toFixed(this.contractCurrenci.Decimal);
    }
    else {
      this.cantidadDisponible = +(cantidad - cantidadTotalDistribucion).toFixed(2);
      this.montoDisponible = +montoPendiente.toFixed(this.contractCurrenci.Decimal);
    }

    // this.formModificarDistribucion.controls['cantidad'].setValidators([Validators.required, Validators.max(this.cantidadDisponible)]);

    this.formModificarDistribucion.controls['cantidad'].setValidators([Validators.required]);
    this.formModificarDistribucion.controls['monto'].setValidators([Validators.required, (Validators.max(this.montoDisponible +100000))]);
    this.formModificarDistribucion.controls['cantidad'].updateValueAndValidity()
    this.formModificarDistribucion.controls['monto'].updateValueAndValidity()

  }
  changeModCantidad() {
    /* let valor = this.formModificarDistribucion.value.cantidad;
    this.formModificarDistribucion.controls.monto.setValue((valor * this.precioUnitario).toFixed(2)); */


    let valor = this.formModificarDistribucion.value.cantidad;
    //const reajuste =  ((+this.cabecera.REAJUSTE / 100) + 1);
    this.formAgregarDistribucion.controls.monto.setValue((+valor * this.precioUnitario));
    if (this.precioUnitarioConReajuste > 0) {
      this.formModificarDistribucion.controls.monto.setValue((+valor * this.precioUnitarioConReajuste).toFixed(2));
      //this.formModificarDistribucion.controls.monto.setValue((+valor * this.precioUnitarioConReajuste));
    }
    else {
      this.formModificarDistribucion.controls.monto.setValue((+valor * this.precioUnitario).toFixed(2));
      //this.formModificarDistribucion.controls.monto.setValue((+valor * this.precioUnitario));
    }
  }
  changeModMonto() {
    /* let valor = this.formModificarDistribucion.value.monto;
    this.formModificarDistribucion.controls.cantidad.setValue((valor / this.precioUnitario).toFixed(2)); */

    let valor = this.formModificarDistribucion.value.monto;
    //const reajuste =  ((+this.cabecera.REAJUSTE / 100) + 1);
    if (this.precioUnitarioConReajuste > 0) {
      this.formModificarDistribucion.controls.cantidad.setValue((valor / this.precioUnitarioConReajuste).toFixed(2));
      //this.formModificarDistribucion.controls.cantidad.setValue((valor / this.precioUnitarioConReajuste));
    }
    else {
      this.formModificarDistribucion.controls.cantidad.setValue((valor / this.precioUnitario).toFixed(2));
      //this.formModificarDistribucion.controls.cantidad.setValue((valor / this.precioUnitario));
    }


  }
  onModificarDistribucion() {
    this.submittedModDistribucion = true;
    if (this.formModificarDistribucion.invalid) {
      return;
    }

    const postData: {
      in_ep_distribucion_correlativo: number,
      in_cantidad_asignado: number,
      in_monto_asignado: number
    } = {
      in_ep_distribucion_correlativo: this.formModificarDistribucion.value.corr_distribucion,
      in_cantidad_asignado: this.formModificarDistribucion.value.cantidad,
      in_monto_asignado: this.formModificarDistribucion.value.monto
    };

    let item_correlativo = this.formModificarDistribucion.value.item_correlativo;

    this.estadoPagoService.editaDistribucion(postData).subscribe(resp => {
      this.notifyService.showSuccess('Distribucion editada con exito.', 'Editar Distribucion');
    }, err => {
      this.notifyService.showError('Error al editar Distribucion', 'Editar Distribucion');
      console.log(err);
    }, () => {
      this.resetFormModDist()
      this.getDistribucion(item_correlativo);
      this.closeModalModificarDistribucion.nativeElement.click();
    });
  }

  /** Gestionador SAP */
  private resetFormHES() {
    this.formHES = new FormGroup({
      hes: new FormControl(null, Validators.required)
    });
    this.submittedHES = false;
  }
  onAgregarHES() {
    this.submittedHES = true;
    if (this.formHES.invalid) {
      return;
    }

    this.estadoPagoService.relacionaHES_EP({ in_ep_correlativo: this.corrEP, iv_hes_codigo: this.formHES.value.hes, in_usuario_correlativo_creacion: 0 }).subscribe(resp => {
      this.notifyService.showSuccess('HES relacionado con exito.', 'Estado de Pago');
    }, err => {
      this.notifyService.showError('Error al relacionar HES', 'Estado de Pago');
      console.log(err);
    }, () => {
      this.avanzarAFacturacion();
    });
  }

  /** Agregar datos de facturacion */
  private resetFormFacturacion() {
    this.formFinanzas = new FormGroup({
      nro_factura: new FormControl(null, Validators.required),
      adjunto: new FormControl(null, Validators.required),
      fecha_pago: new FormControl(null, Validators.required)
    });
    this.submittedFacturacion = false;
    this.fileNameFactura = null;
  }
  detectFileFacturacion(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64Factura = bs64
          this.fileNameFactura = event.target.files[0].name;
          this.typeFactura = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2);
        }
      };
    }
  }
  onAgregarFacturacion() {
    this.submittedFacturacion = true;
    if (this.formFinanzas.invalid) {
      return;
    }

    if (this.fileNameFactura) {
      const postDataFile: { fileName: string, type: string, files: string } = { fileName: this.fileNameFactura, type: this.typeFactura, files: this.archivos64Factura }
      this.addonsService.postFileFacturaEP(postDataFile).subscribe(resp => {
        this.postFacturacion(JSON.parse(resp));
      });
    }
    return;
  }
  private postFacturacion(nombreArchivo: string) {
    const postData: {
      in_ep_correlativo: number,
      in_nro_factura: number,
      iv_fecha_pago: string,
      iv_nombre_archivo: string,
      in_usua_correlativo: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_nro_factura: this.formFinanzas.value.nro_factura,
      iv_fecha_pago: this.formFinanzas.value.fecha_pago,
      iv_nombre_archivo: nombreArchivo,
      in_usua_correlativo: 0
    };

    this.estadoPagoService.relacionaEPFacturacion(postData).subscribe(resp => {
      this.notifyService.showSuccess('Facturación relacionada con exito.', 'Estado de Pago');
    }, err => {
      this.notifyService.showError('Error al relacionar Facturación', 'Estado de Pago');
      console.log(err);
    }, () => {
      this.finalizarEtapa();
    });
  }
  groupBy(arr, keyGetter) {
    var map = new Map();
    arr.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      }
      else {
        collection.push(item);
      }
    });
    return map;
  }
  sumTotalGrafo(arr) {
    return arr.reduce((sum, current) => sum + current.MONTO_ASIGNADO, 0);
  }
  sumTotalGrafoV1(arr) {
    return arr.reduce((sum, current) => sum + (current.valor / this.cabecera.EXCHAGE_RATE), 0);
  }
  getGrafoName(id) {
    return this.grafos?.filter(grafo => grafo.CODIGO === id)
  }
  sumComprobante(): number {
    return this.aComprobante?.reduce((sum, current) => sum + (current.monto * current.ExchageRate), 0);
  }
  reeviarGrafos() {
    //if(this.fileNameFactura) {
    const postDataFile: { pin_ep_correlativo: number } = { pin_ep_correlativo: this.corrEP }
    this.estadoPagoService.postReenviaGrafosEP(postDataFile).subscribe(resp => {
      //this.postFacturacion(JSON.parse(resp));
      this.notifyService.showSuccess('Grafos reenviados.', 'Reenviar Grafos');
      this.avanzarARespGrafos();
      this.getDistribucion();
      this.getComprobante();
      this.getHistorial();
    }, err => {
      this.notifyService.showError('Error al reenviar grafos', 'Reenviar Grafos');
      console.log(err);
    });
    //}
    return;
  }
  probarComprobanteXls() {
    const postDataFile: { pin_ep_correlativo: number } = { pin_ep_correlativo: this.corrEP }
    this.estadoPagoService.getComprobanteContable(postDataFile.pin_ep_correlativo).subscribe(resp => {
      //this.postFacturacion(JSON.parse(resp));
      this.notifyService.showSuccess('Comprobante generado', 'Comprobante generado');
      this.avanzarAGestSAP();
      //this.getDistribucion();
    }, err => {
      this.notifyService.showError('Error al generar el archivo xlsx', 'Error XLSX');
      console.log(err);
    });
  }
  public getComentariosDistribucion(id_ep_distribucion) {
    this.idDistribucion = id_ep_distribucion;
    this.estadoPagoService.getDistribucionEPComentarios(id_ep_distribucion).subscribe(resp => {
      this.distribucionesComentarios = JSON.parse(resp);
    });
  }
  public onGuardarTasa() {
    /* this.ExhageRate.value
    alert(this.cabecera.EXCHAGE_RATE); */
    //alert(this.ExchangeRate.value);
    const postDataFile: { pin_ep_correlativo: number, pin_ExchangeRate: number } = { pin_ep_correlativo: this.corrEP, pin_ExchangeRate: this.ExchangeRate.value };
    console.log(this.ExchangeRate.value);

    if(this.ExchangeRate.value <= 0){
      this.notifyService.showWarning('Seleccione una tasa valida.', 'Actualizar estado de pago');
      return;
    }else{
      this.estadoPagoService.postExchangeRateEstadoPago(postDataFile).subscribe(resp => {
        //this.distribucionesComentarios = JSON.parse(resp);
        this.notifyService.showSuccess('El estado de pago se actualizo correctamente.', 'Actualizar tasa de cambio');
      }, err => {
        this.notifyService.showError('Error al actulizar estado de pago', 'Actualizar estado de pago');
      }, () => {
        this.closeModalGeneral.nativeElement.click();
        this.getCabecera();
      });
    }
  }

  public onGuardarModificacionGeneral()
  {
    const postData: {
      cont_correlativo: number,
      descripcion: string,
      periodo_desde: string,
      periodo_hasta: string,
      reajuste: number,
      ExchageRate: number,
      correlativo: number
    } = {
      cont_correlativo: this.cabecera.CONT_CORRELATIVO,
      descripcion: this.DescricionEP.value,
      periodo_desde: this.PeriodoDesde.value,
      periodo_hasta: this.PeriodoHasta.value,
      reajuste: this.ReajusteEp.value ? this.ReajusteEp.value : 0,
      ExchageRate: this.cabecera.EXCHAGE_RATE,
      correlativo: this.corrEP
    };

    this.estadoPagoService.updateCabeceraEP(postData).subscribe(resp => {
        this.notifyService.showSuccess('El estado de pago se actualizo correctamente.', 'Actualizar estado de pago'); 
      }, 
      err => {
        this.notifyService.showError('Error al actulizar estado de pago', 'Actualizar estado de pago');
      }, 
      () => {
        this.closeModalGeneral.nativeElement.click();
        this.getCabecera();
        this.getDetalle();
      }
    );
  }

  agregarComentarioDistribucion() {
    const postData: {
      in_ep_distibucion_correlativo: number,
      in_comment: string,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_distibucion_correlativo: this.idDistribucion,
      in_comment: this.formAgregarComentarioDistribucion.value.comentariodist,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.postDistribucionEPComentario(postData).subscribe(resp => {
      this.notifyService.showSuccess('Comentario creado con exito.', 'Crear Comentario');
    }, err => {
      this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
      console.log(err);
    }, () => {
      //this.getComentarios();
      //this.resetFormComentario();
      this.formAgregarComentarioDistribucion.patchValue(
        {
          comentariodist: ''
        }
      )
      this.getComentariosDistribucion(this.idDistribucion)
    });
  }

  agregarComentarioDistribucionRechazo() {
    const postData: {
      in_ep_distibucion_correlativo: number,
      in_comment: string,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_distibucion_correlativo: this.correlativoDistribucion,
      in_comment: this.formRechazarDistribucion.value.comentariodistrechazo,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.postDistribucionEPComentario(postData).subscribe(resp => {
      this.notifyService.showSuccess('Comentario creado con exito.', 'Crear Comentario');
      this.rechazarDistribucion();
    }, err => {
      this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
      console.log(err);
    }, () => {
      //this.getComentarios();
      //this.resetFormComentario();
      this.formRechazarDistribucion.patchValue(
        {
          comentariodistrechazo: ''
        }
      )
      this.getComentariosDistribucion(this.idDistribucion)
    });
  }

  private getComprobante() {
    this.estadoPagoService.getComprobanteEP(this.corrEP).subscribe(resp => {
      const response: any[] = JSON.parse(resp);
      this.totalM = response[0]?.totalM;
      this.TotalReajuste = 0;
      // this.aComprobante = response.map(comprobante => {
      //   return {
      //     ...comprobante,
      //     original: formatCurrency(comprobante.monto, comprobante?.Decimal),
      //     clp: formatCurrency(comprobante.monto * comprobante.ExchageRate, 0),
      //     subtotal: formatCurrency(comprobante.monto, comprobante?.Decimal),
      //   }
      // });

      if (this.cabecera?.EXCHAGE_RATE == 1) {
        this.aComprobante = response.map(comprobante => {
          return {
            ...comprobante,
            original: formatCurrency(comprobante.monto, comprobante?.Decimal),
            clp: formatCurrency(comprobante.monto * comprobante.ExchageRate, 0),
            subtotal: formatCurrency(comprobante.monto, comprobante?.Decimal),
          }
        });
      }else{
        this.aComprobante = response.map(comprobante => {
          return {
            ...comprobante,
            original: formatCurrency(comprobante.monto, comprobante?.Decimal),
            clp: formatCurrency(comprobante.totalPorLinea, 0),
            subtotal: formatCurrency(comprobante.monto, comprobante?.Decimal),
          }
        });
      }

      this.excelButtonComprobante.headers[4].title = this.aComprobante[0]?.Simbol ?? 'Monto';
      const excelButtonComprobanteData = _.cloneDeep(this.aComprobante);

      const totalMonto = this.aComprobante.reduce((sum, current) => sum + +current.monto, 0);
      const totalClp = this.sumComprobante();
      excelButtonComprobanteData.push(
        {},
        {
          original: 'TOTAL EP',
          clp: 'TOTAL EP CLP',
        },
        // {
        //   original: formatCurrency(totalMonto, this.aComprobante[0]?.Decimal ?? 2),
        //   clp: formatCurrency(totalClp, 0),
        // }
        {
          original: formatCurrency(totalMonto, this.aComprobante[0]?.Decimal ?? 2),
          clp: formatCurrency(totalClp, 0),
        }
      );

      this.excelButtonComprobante.data = excelButtonComprobanteData;
    }, error => console.log(error),
      () => {
        /*  this.setearDetalles();
          for(let i of this.detalleEP) {
            this.totalDetalleEP += i.CantidadSolicitada;
            this.totalDetalleMontoEP += i.PrecioUnitario * i.CantidadSolicitada;
          }
          this.getDistribucion(); */

      });

  }
  private getDistribucionV1() {
    this.contratoService.getEPDistribucionV1(this.corrEP).subscribe(resp => {
      this.distribucionV1 = JSON.parse(resp)
      //console.log(this.distribucionV1)
      this.distribucionesGrafosV1 = Array.from(this.groupBy(this.distribucionV1, distribuciones => distribuciones.grafo));
      //console.log(this.distribucionesGrafosV1)
    })
  }
  onCloseModGeneral()
  {
    this.closeModalGeneral.nativeElement.click();
  }
  agregarComentarioEPRechazo() {
    /* const postData: {
      in_ep_correlativo: number,
      in_comment: string,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      in_comment: this.formRechazarDistribucion.value.comentariodistrechazo,
      in_usua_correlativo_creacion: 0
    }; */

    const postData: {
      in_ep_correlativo: number,
      iv_comentario: string,
      in_usua_correlativo_creacion: number
    } = {
      in_ep_correlativo: this.corrEP,
      iv_comentario: "Motivo de rechazo: " + this.formRechazarDistribucion.value.comentariodistrechazo,
      in_usua_correlativo_creacion: 0
    };

    if (this.cabecera.ESDO_EP == 137) {
      if (this.distribuciones.length > 0) {
        this.notifyService.showWarning('Debe eliminar la distribución completamente para poder rechazar', 'Rechazar EP');
        return;
      } 
    }

    this.estadoPagoService.registraComentarios(postData).subscribe(resp => {
      this.notifyService.showSuccess('Comentario creado con exito.', 'Crear Comentario');
      if((this.cabecera.ESDO_EP == 101 || this.cabecera.ESDO_EP == 107) && this.rolId == 2)
        this.rechazaAdminEMSA()

      if((this.cabecera.ESDO_EP == 105 || this.cabecera.ESDO_EP == 137) && this.rolId == 20){
        if (this.cabecera.ESDO_EP == 137) {
          if (this.distribuciones.length <= 0) {
            this.rechazaPYC();
          } else {
            return;
          }
        } else {
          this.rechazaPYC();
        }
      }
      //this.rechazarDistribucion();
    }, err => {
      this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
      console.log(err);
    }, () => {
      this.getComentarios();
      //this.resetFormComentario();
      this.formRechazarEP.patchValue(
        {
          comentariodistrechazo: ''
        }
      )
      //this.getComentariosDistribucion(this.idDistribucion)
    });
  }
  mergeInfoSheet(data: any) {
    this.infoSheet.data = [Object.assign(this.infoSheet.data[0] || {}, data)];
    this.excelButtonComprobante.infoSheet = this.infoSheet;
    this.excelButtonGrafo.infoSheet = this.infoSheet;
    this.excelButtonHistorial.infoSheet = this.infoSheet;
  }
  private getItemizado() {
    this.estadoPagoService.getItemizadoDetalle(this.cabecera.CONT_CORRELATIVO).subscribe(resp => {
      let response = JSON.parse(resp);
      let idx = 0;
      let cabecera = null;
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
        if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Cabecera: response[i]['ITEM_CABECERA'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            AvanceQ: null,
            PrecioUnitario: null,
            Correlativo: null,
            R: null,
            Scpq: null
          };
          // primer subitem
          idx++;
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            AvanceQ: response[i]['AVANCE_Q'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ'],
            CantidadSolicitada: this.getCantidadSolicitadaById(response[i]['ID_CORRELATIVO']),
            idDetalleEstadoPago: this.getIdDetallePagoById(response[i]['ID_CORRELATIVO'])
          };
          idx++;
        // de lo contrario sigo registrando subitems hasta que cambie el item principal
        } else {
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            AvanceQ: response[i]['AVANCE_Q'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ'],
            CantidadSolicitada: this.getCantidadSolicitadaById(response[i]['ID_CORRELATIVO']),
            idDetalleEstadoPago: this.getIdDetallePagoById(response[i]['ID_CORRELATIVO'])
          };
          idx++;
        }

        cabecera = response[i]['ITEM_CABECERA'];
      }
    });
  }
  private getCantidadSolicitadaById(idCorrelativo: number)
  {
    let index = this.detalleEP.map(function(det)
    {
      return det.Correlativo
    }
    ).indexOf(idCorrelativo)

    if(index > 0)
    {
      return this.detalleEP[index].CantidadSolicitada;
    }
    else
    {
      return 0;
    }
    //return index;
  }
  private getIdDetallePagoById(idCorrelativo: number)
  {
    let index = this.detalleEP.map(function(det)
    {
      return det.Correlativo
    }
    ).indexOf(idCorrelativo)

    if(index > 0)
    {
      return this.detalleEP[index].DetCorrelativo;
    }
    else
    {
      return 0;
    }
  }
  returIndexOnItemizado(idCorrelativo: number)
  {
    return this.itemizado.map(function(det)
    {
      return det.Correlativo
    }
    ).indexOf(idCorrelativo)
  }

  returIndexOnDetalleEP(idCorrelativo: number)
  {
    return this.detalleEP.map(function(det)
    {
      return det.DetCorrelativo
    }
    ).indexOf(idCorrelativo)
  }

  returIndexOnDetalleEPItem(idCorrelativo: number)
  {
    return this.detalleEP.map(function(det)
    {
      return det.Correlativo
    }
    ).indexOf(idCorrelativo)
  }

  public actulizarCantidades2(in_det_ep_correlativo: number, in_cantidad: number, corr: number)
  {
    const postDataFile: { correlativo: number,
      ep_correlativo: number,
      cantidad: number,
      idCorrelativo: number,
      vigente: string } = { correlativo: in_det_ep_correlativo, ep_correlativo: this.corrEP, cantidad: in_cantidad, idCorrelativo: corr,vigente: 'S' }
      this.estadoPagoService.postEstadoPagoDetalle(postDataFile).subscribe(resp => {
      const lineCambiada = JSON.parse(resp);
      if(lineCambiada.estadoPagoDetalle.correlativo > 0)
      {
        //case agregar o update
        const index = this.returIndexOnDetalleEP(lineCambiada.estadoPagoDetalle.correlativo)
        if(index > -1 )
        {
          this.detalleEP[index].CantidadSolicitada = lineCambiada.estadoPagoDetalle.cantidad
          this.detalleEP[index].DetCorrelativo = lineCambiada.estadoPagoDetalle.correlativo
          const itemizadoIndex  = this.returIndexOnItemizado(lineCambiada.estadoPagoDetalle.idCorrelativo)
          this.itemizado[itemizadoIndex].idDetalleEstadoPago = lineCambiada.estadoPagoDetalle.correlativo
          this.itemizado[itemizadoIndex].CantidadSolicitada = lineCambiada.estadoPagoDetalle.cantidad
          this.calcularTotales()
        }
        else
        {
          const itemizadoIndex = this.returIndexOnItemizado(lineCambiada.estadoPagoDetalle.idCorrelativo)
          this.itemizado[itemizadoIndex].idDetalleEstadoPago = lineCambiada.estadoPagoDetalle.correlativo
          this.itemizado[itemizadoIndex].CantidadSolicitada = lineCambiada.estadoPagoDetalle.cantidad
          this.getDetalle();
          this.calcularTotales()
          //this.detalleEP.push(lineCambiada);
        }
      }
      else
      {
        //case delete
        const index = this.returIndexOnDetalleEPItem(lineCambiada.estadoPagoDetalle.idCorrelativo)
        if(index > -1 )
        {
          this.detalleEP.splice(index, 1);
        }
        this.calcularTotales()
      }
      //this.getCabecera();
    });
    //alert(JSON.stringify(postDataFile))
  }
  getTasas()
  {
    //this.tasas = [{date: '2023-08-01', value: 1},{date: '2023-08-02', value: 4}]
    this.estadoPagoService.getExchageRateTaxes(this.corrEP).subscribe(resp => {
      this.tasas = JSON.parse(resp);
      // this.cabecera.ESDO_EP
      const ultimaActualizacionTasa: any = this.tasas[this.tasas.length-1];
      this.tasaCambioDelDia = +ultimaActualizacionTasa.value;
      this.ExchangeRate = new FormControl(this.tasaCambioDelDia);
    });
  }

  sumatoriaLineas(precioUnitario: number, cantidadSolicitada: number, reajuste: number, itemR: number, arreglo: any[], correlativo: number){
    let suma: number = 0;

    let valor: number = ((precioUnitario * cantidadSolicitada) * (((reajuste / 100) * itemR) + 1));

    for (let index = 0; index < arreglo.length; index++) {
      const element = arreglo[index];
      
      if (element.ITEMCHK_CORRELATIVO === correlativo) {
        suma = suma + element.MONTO_ASIGNADO;
      }
    }
    
    return +(valor - suma).toFixed(this.contractCurrenci?.Decimal);
  }

  pendientePorDistribuir(){
    let result: number = (this.TotalEPActual - this.totalMontoDistribucion);
    return result;
  }

  async onDescargarPDF() {
    this.downloadingPdf = true;
    await delay(1000);

    // 'Comentarios',
    const doc = new PDF(
      { format: "A2", unit: "mm" },
      [
        '',
        'Historial',
        'Archivos'
      ]
    );

    doc.addLogo();
    doc.addTitle(`Contrato ${this.contrato.CODIGO_CONTRATO} ${this.contrato.NOMBRE_CONTRATO }`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 100;

    doc.identText(
      "No.",
      this.corrEP.toString(),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Proveedor",
      this.contrato?.PROVEEDOR,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Estado",
      this.cabecera?.ESDO_EP_DESC,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Desde",
      this.cabecera?.EP_PERIODO_DESDE ? moment(this.cabecera?.EP_PERIODO_DESDE, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Hasta",
      this.cabecera?.EP_PERIODO_HASTA ? moment(this.cabecera?.EP_PERIODO_HASTA, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Descripción",
      this.cabecera?.EP_DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Moneda",
      this.contrato?.TIPO_MONEDA || 'CLP',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "HES",
      this.cabecera?.HES || '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Factura",
      this.cabecera?.NRO_FACTURA || '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha de pago",
      this.fechaPagoEp || '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.increasePageY(5);

    doc.identText(
      "Detalle",
      '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.increasePageY(5);

    doc.addTableHtml('#table_detalle');

    //SEGUNDA PAGINA

    // doc.iSection = 2;
    // doc.addNewPage();

    // const iLengthTitleSecond = 80.975;
    // const iLengthValueSecond = 100;

    // this.comentariosEP && this.comentariosEP.length > 0 && this.comentariosEP.forEach((comentario) => {
    //   doc.identText(
    //     [
    //       comentario?.NOMBRE_USUARIO,
    //       comentario?.FECHA_COMENTARIO,
    //     ],
    //     comentario.COMENTARIO,
    //     iLengthTitleSecond,
    //     iLengthValueSecond
    //   );
    //   doc.increasePageY(10);
    // });

    //TERCERA PAGINA

    doc.iSection = 2;
    doc.addNewPage();

    const historial = Array.isArray(this.historial) && this.historial.length > 0 ? this.historial.map(historial => {
      return [
        moment(historial?.FECHA_CREACION).format('DD/MM/YYYY HH:mm:ss'),
        historial?.NOMBRE_USUARIO,
        historial?.TIPO,
        historial?.ESTADO,
        historial?.GRAFO,
        historial?.RESP
      ]
    }) : [];

    doc.addTable(
      [['Fecha', 'Usuario', 'Tipo', 'Estado', 'Grafo', 'Responsable']],
      historial
    );

    //CUARTA PAGINA

    doc.iSection = 3;
    doc.addNewPage();

    if(this.checklist) {
      this.checklist.forEach((documento, i) => {
        if(!documento.Cabecera) {
          if(documento?.NombreArchivo) doc.text(documento?.ItemChk || '', doc.iMargin, doc.nPageYAxi);
        } else {
          if(i !== 0) doc.increasePageY(7);
          doc.text(documento?.Cabecera || '', doc.iMargin, doc.nPageYAxi);
        }
        doc.increasePageY(5);
      })
    }

    doc.addFooter();
    doc.save(`ep_${this.corrEP}.pdf`);
    this.downloadingPdf = false;
  }
  get fd() { return this.formAgregarArchivo.controls; }
  get fc() { return this.formAgregarComentario.controls; }
  get fdist() { return this.formAgregarDistribucion.controls; }
  get fhes() { return this.formHES.controls; }
  get ff() { return this.formFinanzas.controls; }
  get fModDist() { return this.formModificarDistribucion.controls; }

  getMontoEp(): number {
    if (this.cabecera.ESDO_EP == 104 || this.cabecera.ESDO_EP == 108 || this.cabecera.ESDO_EP == 109) {
      if (this.aComprobante.length > 0) {
        return (this.sumComprobante() / this.cabecera.EXCHAGE_RATE);
      } else {
        return this.TotalEPActual;
      }
    } else {
      return this.TotalEPActual;
    }
  }
}