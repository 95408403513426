import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ContratosComponent } from './contratos/contratos.component';
import { EditContratoComponent } from './contratos/edit-contrato/edit-contrato.component';
import { CreateContratoComponent } from './contratos/create-contrato/create-contrato.component';
import { EmailComponent } from './email/email.component';
import { ViewEmailComponent } from './email/view-email/view-email.component';
import { ParametrosComponent } from './parametros/parametros.component';
import { RolesComponent } from './roles/roles.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AccionesComponent } from './acciones/acciones.component';
import { UsuariosFuncionalidadComponent } from './usuarios-funcionalidad/usuarios-funcionalidad.component';
import { BoletasComponent } from './boletas/boletas.component';
import { CreateBoletaComponent } from './boletas/create-boleta/create-boleta.component';
import { SolpeComponent } from './solpe/solpe/solpe.component';
import { ModificacionesContratoComponent } from './contratos/modificaciones/modificaciones-contrato/modificaciones-contrato.component';
import { SolpeDetalleComponent } from './solpe/solpe-detalle/solpe-detalle.component'
import { MantenedoresComponent } from './solpe/mantenedores/mantenedores.component';
import { ItemizadoComponent } from './contratos/itemizado/itemizado/itemizado.component';
import { EstadosPagoComponent } from './contratos/estados-pago/estados-pago/estados-pago.component';
import { ChecklistComponent } from './contratos/checklist/checklist/checklist.component';
import { ItemsComponent } from './contratos/mantenedores/items/items.component';
import { ChecklistComponent as ChecklistComponentEP } from './contratos/estados-pago/checklist/checklist.component'
import { AllEpComponent } from './contratos/estados-pago/all-ep/all-ep.component';
import { ItemizadoDetalleComponent } from './contratos/itemizado/itemizado-detalle/itemizado-detalle.component';
import { DashboardItemizadoComponent } from './contratos/itemizado/dashboard-itemizado/dashboard-itemizado.component';
import { ItemizadoAprobacionComponent } from './contratos/itemizado/itemizado-aprobacion/itemizado-aprobacion.component';
import { DashboardItemizadoModComponent } from './contratos/itemizado-mod/dashboard-itemizado-mod/dashboard-itemizado-mod.component';
import { CrearItemizadoComponent } from './contratos/itemizado-mod/crear-itemizado/crear-itemizado.component';
import { ItemizadoDetalleModComponent } from './contratos/itemizado-mod/itemizado-detalle-mod/itemizado-detalle-mod.component';
import { ItemizadoAprobacionModComponent } from './contratos/itemizado-mod/itemizado-aprobacion-mod/itemizado-aprobacion-mod.component';
import { PeriodosContratosComponent } from './contratos/periodos-contratos/periodos-contratos.component';
import { ProveedoresComponent } from './contratos/proveedores/proveedores.component';
import { EstadoPagoIncurridoComponent } from './contratos/estados-pago/estado-pago-incurrido/estado-pago-incurrido.component';
import { AllEpIncurridoComponent } from './contratos/estados-pago/all-ep-incurrido/all-ep-incurrido.component';
import { UsuariosCargosComponent } from './usuarios/usuarios-cargos/usuarios-cargos.component';
import { LogsComponent } from './auditoria/logs/logs.component';
import { ContratoDetalleComponent } from './contratos/contrato-detalle/contrato-detalle.component';
import { HelpFilesComponent } from './help-files/help-files.component';
import { ManualesProcedimientosComponent } from './manuales-procedimientos/manuales-procedimientos.component';
import { MantenedorAuditorInternoComponent } from './mantenedor-auditor-interno/mantenedor-auditor-interno.component';

const appRoutes: Routes=[
    {path: '', redirectTo: '/auth', pathMatch:'full'},
    {path: 'auth', component: AuthComponent},
    {path: 'auth/:section', component: AuthComponent},
    {
        path: 'home',
        canActivate:[AuthGuard],
        component: HomeComponent
    },
    {
        path: 'create-contrato',
        canActivate:[AuthGuard],
        component: CreateContratoComponent
    },
    {
        path: 'email',
        canActivate:[AuthGuard],
        component: EmailComponent
    },
    {
        path: 'parametros',
        canActivate:[AuthGuard],
        component: ParametrosComponent
    },
    {
        path: 'acciones',
        canActivate:[AuthGuard],
        component: AccionesComponent
    },
    {
        path: 'roles',
        canActivate:[AuthGuard],
        component: RolesComponent
    },
    {
        path: 'usuarios',
        canActivate:[AuthGuard],
        component: UsuariosComponent
    },
    {
        path: 'view-emails',
        canActivate:[AuthGuard],
        component: ViewEmailComponent
    },
    {
        path: 'contratos',
        canActivate:[AuthGuard],
        component: ContratosComponent
    },
    {
      path: 'detalle-contrato/:corr_contrato/:corr_solpe',
      canActivate:[AuthGuard],
      component: ContratoDetalleComponent
    },
    {
        path: 'edit-contrato/:idContrato',
        canActivate:[AuthGuard],
        component: EditContratoComponent
    },
    {
        path: 'usuarios-funcionalidad',
        canActivate:[AuthGuard],
        component: UsuariosFuncionalidadComponent
    },
    {
        path: 'boletas',
        canActivate:[AuthGuard],
        component: BoletasComponent
    },
    {
        path: 'create-boleta',
        canActivate:[AuthGuard],
        component: CreateBoletaComponent
    },
    {
      path: 'solpe',
      canActivate:[AuthGuard],
      component: SolpeComponent
    },
    {
      path: 'solpe/:solpe_type',
      canActivate:[AuthGuard],
      component: SolpeComponent
    },
    {
      path: 'modificaciones-contrato',
      canActivate:[AuthGuard],
      component: ModificacionesContratoComponent
    },
    {
      path: 'detalle-solpe/:solpe_correlativo',
      canActivate:[AuthGuard],
      component: SolpeDetalleComponent
    },
    {
      path: 'mantenedores-solpe',
      canActivate:[AuthGuard],
      component: MantenedoresComponent
    },
    {
      path: 'itemizado/:corr_contrato',
      canActivate:[AuthGuard],
      component: ItemizadoComponent
    },
    {
      path: 'estados-pago',
      canActivate:[AuthGuard],
      component: AllEpComponent
    },
    {
      path: 'estados-pago-incurrido',
      canActivate:[AuthGuard],
      component: AllEpIncurridoComponent
    },
    {
      path: 'nuevo-ep/:corr_contrato',
      canActivate:[AuthGuard],
      component: EstadosPagoComponent
    },
    {
      path: 'nuevo-ep-incurrido/:corr_contrato',
      canActivate:[AuthGuard],
      component: EstadoPagoIncurridoComponent
    },
    {
      path: 'checklist',
      canActivate:[AuthGuard],
      component: ChecklistComponent
    },
    {
      path: 'mantenedor-items',
      canActivate:[AuthGuard],
      component: ItemsComponent
    },
    {
      path: 'estado-pago-checklist/:id_ep',
      canActivate:[AuthGuard],
      component: ChecklistComponentEP
    },
    {
      path: 'itemizado-detalle/:corr_contrato',
      canActivate:[AuthGuard],
      component: ItemizadoDetalleComponent
    },
    {
      path: 'dashboard-itemizado',
      canActivate:[AuthGuard],
      component: DashboardItemizadoComponent
    },
    {
      path: 'itemizado-aprobacion/:corr_contrato/:corr_estado_itemizado',
      canActivate:[AuthGuard],
      component: ItemizadoAprobacionComponent
    },
    {
      path: 'dashboard-itemizado-mod',
      canActivate:[AuthGuard],
      component: DashboardItemizadoModComponent
    },
    {
      path: 'crear-itemizado/:corr_contrato/:corr_solpe',
      canActivate:[AuthGuard],
      component: CrearItemizadoComponent
    },
    {
      path: 'itemizado-detalle-mod/:corr_contrato/:corr_solpe',
      canActivate:[AuthGuard],
      component: ItemizadoDetalleModComponent
    },
    {
      path: 'itemizado-aprobacion-mod/:corr_contrato/:corr_estado_itemizado/:corr_solpe',
      canActivate:[AuthGuard],
      component: ItemizadoAprobacionModComponent
    },
    {
      path: 'periodos-contratos',
      canActivate:[AuthGuard],
      component: PeriodosContratosComponent
    },
    {
      path: 'proveedores',
      canActivate:[AuthGuard],
      component: ProveedoresComponent
    },
    {
      path: 'usuarios-roles',
      canActivate:[AuthGuard],
      component: UsuariosCargosComponent,
    },
    {
      path: 'logs',
      canActivate:[AuthGuard],
      component: LogsComponent
    },
    {
      path: 'help',
      canActivate:[AuthGuard],
      component: HelpFilesComponent
    },
    {
      path: 'manuales-procedimientos',
      canActivate:[AuthGuard],
      component: ManualesProcedimientosComponent
    },
    {
      path: 'mantenedor-auditor-interno',
      canActivate:[AuthGuard],
      component: MantenedorAuditorInternoComponent
    },
    { path: '**', component: NotFoundComponent  }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled',anchorScrolling: 'enabled' } )],
    exports: [RouterModule]
})
export class AppRoutingModule{

}
