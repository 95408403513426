import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelect2Module } from 'ng-select2';
import { TreeviewModule } from 'ngx-treeview';
import { ToastrModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpPluginInterceptor } from './interceptors/http.interceptor';
import localeCl from '@angular/common/locales/es-CL';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { MorrisJsModule } from 'angular-morris-js';
import { AppRoutingModule } from './app-routing-module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BoletasComponent } from './boletas/boletas.component';
import { CreateBoletaComponent } from './boletas/create-boleta/create-boleta.component';
import { EditBoletaComponent } from './boletas/edit-boleta/edit-boleta.component';
import { ContratosComponent } from './contratos/contratos.component';
import { EditContratoComponent } from './contratos/edit-contrato/edit-contrato.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateContratoComponent } from './contratos/create-contrato/create-contrato.component';
import { EmailComponent } from './email/email.component';
import { ViewEmailComponent } from './email/view-email/view-email.component';
import { ParametrosComponent } from './parametros/parametros.component';
import { RolesComponent } from './roles/roles.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AccionesComponent } from './acciones/acciones.component';
import { UsuariosFuncionalidadComponent } from './usuarios-funcionalidad/usuarios-funcionalidad.component';
import { SolpeComponent } from './solpe/solpe/solpe.component';
import { ModificacionesContratoComponent } from './contratos/modificaciones/modificaciones-contrato/modificaciones-contrato.component';
import { SolpeDetalleComponent } from './solpe/solpe-detalle/solpe-detalle.component';
import { MantenedoresComponent } from './solpe/mantenedores/mantenedores.component';
import { ItemizadoComponent } from './contratos/itemizado/itemizado/itemizado.component';
import { EstadosPagoComponent } from './contratos/estados-pago/estados-pago/estados-pago.component';
import { ChecklistComponent } from './contratos/checklist/checklist/checklist.component';
import { ItemsComponent } from './contratos/mantenedores/items/items.component';
import { ChecklistComponent as ChecklistComponentEP } from './contratos/estados-pago/checklist/checklist.component';
import { AllEpComponent } from './contratos/estados-pago/all-ep/all-ep.component';
import { ItemizadoDetalleComponent } from './contratos/itemizado/itemizado-detalle/itemizado-detalle.component';
import { DashboardItemizadoComponent } from './contratos/itemizado/dashboard-itemizado/dashboard-itemizado.component';
import { ItemizadoAprobacionComponent } from './contratos/itemizado/itemizado-aprobacion/itemizado-aprobacion.component';
import { DashboardItemizadoModComponent } from './contratos/itemizado-mod/dashboard-itemizado-mod/dashboard-itemizado-mod.component';
import { CrearItemizadoComponent } from './contratos/itemizado-mod/crear-itemizado/crear-itemizado.component';
import { ItemizadoDetalleModComponent } from './contratos/itemizado-mod/itemizado-detalle-mod/itemizado-detalle-mod.component';
import { ItemizadoAprobacionModComponent } from './contratos/itemizado-mod/itemizado-aprobacion-mod/itemizado-aprobacion-mod.component';
import { PeriodosContratosComponent } from './contratos/periodos-contratos/periodos-contratos.component';
import { ProveedoresComponent } from './contratos/proveedores/proveedores.component';
import { EstadoPagoIncurridoComponent } from './contratos/estados-pago/estado-pago-incurrido/estado-pago-incurrido.component';
import { AllEpIncurridoComponent } from './contratos/estados-pago/all-ep-incurrido/all-ep-incurrido.component';
import { ModalDocumentsComponent } from './shared/modal-documents/modal-documents.component';
import { ViewFileButtonsComponent } from './shared/view-file-button/view-file-button.component';
import { SafePipe } from './shared/pipe.pipe';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ModalChangePasswordComponent } from './header/modal-change-password/modal-change-password.component';
import { UsuariosCargosComponent } from './usuarios/usuarios-cargos/usuarios-cargos.component';
import { TableComponent } from './shared/components/table/table.component';
import { LogsComponent } from './auditoria/logs/logs.component';
import { ViewExcelButtonsComponent } from './shared/view-excel-button/view-excel-buttons.component';
import { ActionButtonComponent } from './shared/components/action-button/action-button.component';
import { ContratoDetalleComponent } from './contratos/contrato-detalle/contrato-detalle.component';
import { PrintButtonComponent } from './shared/components/print-button/print-button.component';
import { NavTabComponent } from './shared/components/nav-tabs/components/nav-tab/nav-tab.component';
import { NavTabsComponent } from './shared/components/nav-tabs/nav-tabs.component';
import { ViewFileLinkComponent } from './shared/view-file-link/view-file-link.component';
import { ModalReloadSessionComponent } from './header/modal-reload-session/modal-reload-session.component';
import { DecimalPlacesDirective } from './shared/directives/decimal-places.directive';
import { TableFilterEpPipe } from './table-filter-ep.pipe';
import { HelpFilesComponent } from './help-files/help-files.component';
import { ManualesProcedimientosComponent } from './manuales-procedimientos/manuales-procedimientos.component';
import { MantenedorAuditorInternoComponent } from './mantenedor-auditor-interno/mantenedor-auditor-interno.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    BoletasComponent,
    CreateBoletaComponent,
    EditBoletaComponent,
    ContratosComponent,
    EditContratoComponent,
    CreateContratoComponent,
    EmailComponent,
    ViewEmailComponent,
    ParametrosComponent,
    RolesComponent,
    UsuariosComponent,
    AccionesComponent,
    UsuariosFuncionalidadComponent,
    SolpeComponent,
    ModificacionesContratoComponent,
    SolpeDetalleComponent,
    MantenedoresComponent,
    ItemizadoComponent,
    EstadosPagoComponent,
    ChecklistComponent,
    ItemsComponent,
    ChecklistComponentEP,
    AllEpComponent,
    ItemizadoDetalleComponent,
    DashboardItemizadoComponent,
    ItemizadoAprobacionComponent,
    DashboardItemizadoModComponent,
    CrearItemizadoComponent,
    ItemizadoDetalleModComponent,
    ItemizadoAprobacionModComponent,
    PeriodosContratosComponent,
    ProveedoresComponent,
    EstadoPagoIncurridoComponent,
    AllEpIncurridoComponent,
    ModalDocumentsComponent,
    ViewFileButtonsComponent,
    SafePipe,
    LoginComponent,
    ForgotPasswordComponent,
    ModalChangePasswordComponent,
    UsuariosCargosComponent,
    TableComponent,
    LogsComponent,
    ViewExcelButtonsComponent,
    ActionButtonComponent,
    ContratoDetalleComponent,
    PrintButtonComponent,
    NavTabComponent,
    NavTabsComponent,
    ViewFileLinkComponent,
    ModalReloadSessionComponent,
    DecimalPlacesDirective,
    TableFilterEpPipe,
    HelpFilesComponent,
    ManualesProcedimientosComponent,
    MantenedorAuditorInternoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MorrisJsModule,
    MatSortModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    MatCheckboxModule,
    ToastrModule.forRoot(),
    TreeviewModule.forRoot(),
    NgSelect2Module,
    MatTabsModule,
    MatDividerModule,
    MatDialogModule,
    MatSlideToggleModule
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpPluginInterceptor,
      multi: true,
    },
    CookieService,
    {
      provide: LOCALE_ID,
      useValue: 'es-CL' 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(){
    registerLocaleData(localeCl, 'es-CL');
  }
}
