<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="bg-white p3-rem rounded">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 class="normalText">
                                        Mantenedor Auditores Internos - Contratos
                                    </h4>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a routerLink="/usuarios" class="btn btn-primary">
                                        <i class="fas fa-lg fa-fw m-r-10 fa-user"></i>
                                        Administrar Usuarios
                                    </a>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" (keyup)="applyFilterAuditores($event)" placeholder="Buscar ..."
                                        class="form-control"
                                        style="margin-bottom: 10px; padding-right: 2rem !important;">
                                    <i matsuffix="" class="fas fa-search"
                                        style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Vigente</th>
                                    <th class="text-left">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of auditoresFiltered">
                                        <ng-container>
                                            <td>
                                                {{ item.nombre }}
                                            </td>
                                            <td>
                                                {{ item.email }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente == 'S'">
                                                    Vigente
                                                </span>
                                                <span class="tab tab-red" *ngIf="item.vigente == 'N'">
                                                    Inactivo
                                                </span>
                                            </td>
                                            <td class="text-right">
                                                <div class="row text-right">
                                                    <a title="Editar Motivo" class="btn rounded-icon mr-2"
                                                        data-toggle="modal" data-target="#contratosRelacionadosModal"
                                                        (click)="editarContratosAuditor(item.contratosRelacionados, item.usuario_correlativo)">
                                                        <i class="fa fa-edit"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal contratos relacionados auditores -->
<div class="modal fade" id="contratosRelacionadosModal" tabindex="-1" role="dialog"
    aria-labelledby="contratosRelacionadosModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="contratosRelacionadosModalLabel">Contratos relacionados</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h5>Contratos Relacionados: {{contContratosRelacionados}} / {{contContratos}}</h5>
                    </div>
                    <div class="col-12 col-md-4">
                        <input matinput="" (keyup)="applyFilterContratos($event)" placeholder="Buscar ..."
                            class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                        <i matsuffix="" class="fas fa-search"
                            style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                    </div>
                </div>
                <div *ngIf="isSectionToggleAll" style="border: 1px solid #ccc; padding: 20px;" class="row">
                    <div class="col-12 col-md-12">
                        <p>{{toggleActionStr}}</p>
                    </div>
                    <div class="col-12 text-right">
                        <button type="button" class="btn tag text-primary mr-2"
                            (click)="cancelarToggleAll()">Cancelar</button>
                        <button type="button" class="btn btn-primary" (click)="onToggleAll()">Guardar</button>
                    </div>
                </div>
                <br>
                <table class="table table-hover">
                    <thead class="thead-darkk">
                        <th><input type="checkbox" (change)="toggleAll($event.target.checked)"
                                [checked]="defaultChecked"></th>
                        <th>Codigo</th>
                        <th>Nombre</th>
                        <th>Estado</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of contratosFiltered">
                            <ng-container>
                                <td>
                                    <input type="checkbox" [checked]="item?.checked"
                                        (click)="toggleContrato(item.correlativo, $event)">
                                </td>
                                <td style="width: 10%;">
                                    {{ item.codigo_contrato }}
                                </td>
                                <td>
                                    {{ item.nombre_contrato }}
                                </td>
                                <td>
                                    <span class="tab tab-green" *ngIf="item.estado_contrato_correlativo == 30">
                                        Vigente
                                    </span>
                                    <span class="tab tab-yellow" *ngIf="item.estado_contrato_correlativo == 31">
                                        Expirado
                                    </span>
                                    <span class="tab tab-red" *ngIf="item.estado_contrato_correlativo == 32">
                                        Finiquitado
                                    </span>
                                    <span class="tab tab-red" *ngIf="item.estado_contrato_correlativo == 33">
                                        Cancelado
                                    </span>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>