import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {

  constructor(private http: HttpClient) { }
  private urlService: string = environment.apiUrl;

  getContratos(in_filtro: number , iv_es_cont_interno: string, idRol: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getContratos/' + in_filtro + '/'+ iv_es_cont_interno +'/'+ idRol, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let contratos = [];
                for (const item in resp['contratos']) {
                  contratos.push({...resp['contratos'][item] });
                }
                return JSON.stringify(contratos);
            }));
  }
  getContratosBusqueda() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getContratosBusqueda', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let contratos = [];
                for (const item in resp['contratos']) {
                  contratos.push({...resp['contratos'][item] });
                }
                return JSON.stringify(contratos);
            }));
  }
  getContratosBusquedaEP(in_usua_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getContratosBusquedaEP' + '/' + in_usua_correlativo + '/' + in_cargo_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let contratos = [];
                for (const item in resp['contratos']) {
                  contratos.push({...resp['contratos'][item] });
                }
                return JSON.stringify(contratos);
            }));
  }
  getContratosBusquedaUsuario(in_usua_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getContratosBusquedaUsuario/' + in_usua_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let contratos = [];
                for (const item in resp['contratos']) {
                  contratos.push({...resp['contratos'][item] });
                }
                return JSON.stringify(contratos);
            }));
  }
  getDashboard() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'avance/getDashboard', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let dashboard = [];
                for (const item in resp['dashboard']) {
                  dashboard.push({...resp['dashboard'][item] });
                }
                return JSON.stringify(dashboard);
            }));
  }
  getEmails() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'email/getEmails', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let emails = [];
                for (const item in resp['emails']) {
                  emails.push({...resp['emails'][item] });
                }
                return JSON.stringify(emails);
            }));
  }
  getEmailsLog() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'email/getEmailsLog', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let emails = [];
                for (const item in resp['emails']) {
                  emails.push({...resp['emails'][item] });
                }
                return JSON.stringify(emails);
            }));
  }
  getDestinatarios() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'email/getDestinatarios', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let destinatarios = [];
                for (const item in resp['destinatarios']) {
                  destinatarios.push({...resp['destinatarios'][item] });
                }
                return JSON.stringify(destinatarios);
            }));
  }
  getDestinatariosUser() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'email/getDestinatariosUser', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let destinatariosUser = [];
                for (const item in resp['destinatariosUser']) {
                  destinatariosUser.push({...resp['destinatariosUser'][item] });
                }
                return JSON.stringify(destinatariosUser);
            }));
  }
  getSistemas() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'email/getSistemas', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let sistemas = [];
                for (const item in resp['sistemas']) {
                  sistemas.push({...resp['sistemas'][item] });
                }
                return JSON.stringify(sistemas);
            }));
  }
  getEmailId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'email/getEmailId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let email = [];
          for (const item in resp['email']) {
            email.push({...resp['email'][item] });
          }
          return JSON.stringify(email);
      }));
  }
  getContratoId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getContratoId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let contrato = [];
          for (const item in resp['contrato']) {
            contrato.push({...resp['contrato'][item] });
          }
          return JSON.stringify(contrato);
      }));
  }
  getBoletasContratoId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getBoletasContratoId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let boletas = [];
          for (const item in resp['boletas']) {
            boletas.push({...resp['boletas'][item] });
          }
          return JSON.stringify(boletas);
      }));
  }
  getDominios(in_tipo : string ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getDominios/' + in_tipo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let dominios = [];
          for (const item in resp['dominios']) {
            dominios.push({...resp['dominios'][item] });
          }
          return JSON.stringify(dominios);
      }));
  }
  getContratoDocumentos(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getContratoDocumentos/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let documentos = [];
          for (const item in resp['documentos']) {
            documentos.push({...resp['documentos'][item] });
          }
          return JSON.stringify(documentos);
      }));
  }
  postcreaArchivoContrato(postData : {
    pin_cont_correlativo: number,
    pin_doc_correlativo: number,
    piv_nom_archivo: string,
    pin_usua_correlativo: number,
    piv_descripcion: string,
    piv_esPublico: string
  } ){
  const token = JSON.parse(localStorage.getItem('userData'))._token;
  postData.pin_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
        .post(
          this.urlService + 'contrato/creaArchivoContrato', postData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
          .pipe(
            map(resp => {
                let documento = [];
                for (const item in resp['documento']) {
                  documento.push({...resp['documento'][item] });
                }
                return JSON.stringify(documento);
            }));

  }
  postContrato(postData : {
    iv_nombre_contrato : string,
    iv_codigo_contrato : string,
    in_tico_correlativo: number,
    iv_proveedor : string,
    in_monto: number,
    in_timo_correlativo: number,
    iv_fecha_contrato : string,
    iv_fecha_inicio_contrato : string,
    iv_fecha_termino_contrato : string,
    in_requiere_bg: number,
    in_tige_correlativo: number,
    in_tiar_correlativo: number,
    in_admin_correlativo: number,
    in_gest_correlativo: number,
    in_tior_correlativo: number,
    in_tiesproc_correlativo: number,
    in_esdo_correlativo: number
  } ){
    //postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'contrato/postContrato', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let contrato = [];
                  for (const item in resp['contrato']) {
                    contrato.push({...resp['contrato'][item] });
                  }
                  return JSON.stringify(contrato);
              }));

  }
  postContratoRevision(postData: { pin_contrato_correlativo})
  {
    return this.http
          .post(
            this.urlService + 'contrato/postContratoRevision', postData)
            .pipe(
              map(resp => {
                  let contrato = [];
                  for (const item in resp['contrato']) {
                    contrato.push({...resp['contrato'][item] });
                  }
                  return JSON.stringify(contrato);
              }));
  }
  crearContrato(postData : {
      iv_anio_contrato : string,
      iv_nombre_contrato : string,
      iv_codigo_contrato : string,
      in_tico_correlativo : number,
      in_proveedor : number,
      in_monto : number,
      in_timo_correlativo : number,
      iv_fecha_contrato : string,
      iv_fecha_inicio_contrato : string,
      iv_fecha_termino_contrato : string,
      iv_requiere_bg : string,
      in_tige_correlativo : number,
      in_tiar_correlativo : number,
      in_admin_correlativo : number,
      in_gest_correlativo : number,
      in_tior_correlativo : number,
      in_tiesproc_correlativo : number,
      in_esdo_correlativo : number,
      in_admin_ec : number,
      in_usua_correlativo_creacion: number,
      iv_es_cont_interno: string
      in_solpe_correlativo: number
    }) {
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'contrato/crearContrato', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let contrato = [];
                  for (const item in resp['contrato']) {
                    contrato.push({...resp['contrato'][item] });
                  }
                  return JSON.stringify(contrato);
              }));

  }
  getAvanceTiempo(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'avance/getAvanceTiempo/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let avance_tiempo = [];
          for (const item in resp['avance_tiempo']) {
            avance_tiempo.push({...resp['avance_tiempo'][item] });
          }
          return JSON.stringify(avance_tiempo);
      }));
  }
  getAvanceFinanciero(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'avance/getAvanceFinanciero/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let avance_financiero = [];
          for (const item in resp['avance_financiero']) {
            avance_financiero.push({...resp['avance_financiero'][item] });
          }
          return JSON.stringify(avance_financiero);
      }));
  }
  getItemizado(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getItemizado/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
      }));
  }
  getHeadItemizado(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getHeadItemizado/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let cabecera = [];
          for (const item in resp['cabecera']) {
            cabecera.push({...resp['cabecera'][item] });
          }
          return JSON.stringify(cabecera);
      }));
  }
  getFootItemizado(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getFootItemizado/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let foot = [];
          for (const item in resp['foot']) {
            foot.push({...resp['foot'][item] });
          }
          return JSON.stringify(foot);
      }));
  }
  postEmail(postData : {
    in_cod_mantencion: number,
    in_mail_correlativo?: number,
    iv_descripcion_email?: string,
    iv_cuerpo_email?: string,
    iv_pie_email?: string,
    iv_asunto?: string,
    iv_vigente ?: string,
    iv_fecha_vigente_desde? : string,
    iv_fecha_vigente_hasta? : string,
    iv_usuario_creacion?: string,
    in_sist_correlativo? : number,
    iv_uso?: string,
    iv_instancia?: string,
    destinatarios?: string,
    iv_datos_variables?: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'email/postEmail', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let email = [];
                  for (const item in resp['email']) {
                    email.push({...resp['email'][item] });
                  }
                  return JSON.stringify(email);
              }));

  }
  postEnviarEmail(postData : {
    iv_nombre_proceso: string,
    in_correlativo: number,
    in_usuario: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'email/postEnviarEmail', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let email = [];
                  for (const item in resp['email']) {
                    email.push({...resp['email'][item] });
                  }
                  return JSON.stringify(email);
              }));

  }
  postEmailPersona(postData : {
    iv_nombre :  string,
    iv_apaterno :  string,
    iv_amaterno :  string,
    iv_email :  string,
    in_usuario :  string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'email/postEmailPersona', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let email = [];
                  for (const item in resp['email']) {
                    email.push({...resp['email'][item] });
                  }
                  return JSON.stringify(email);
              }));

  }
  postRelacionaEmailPersona(postData : {
    in_mame_correlativo :  number,
    in_cargo_correlativo :  number,
    in_mape_correlativo :  number,
    in_usuario :  number
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'email/postRelacionaEmailPersona', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let email = [];
                  for (const item in resp['email']) {
                    email.push({...resp['email'][item] });
                  }
                  return JSON.stringify(email);
              }));

  }
  getModificacionesContrato(iv_tipo_correlativo: string, in_cont_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getModoficacionesContrato/' + iv_tipo_correlativo + '/' + in_cont_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let modificaciones = [];
          for (const item in resp['modificaciones']) {
            modificaciones.push({...resp['modificaciones'][item] });
          }
          return JSON.stringify(modificaciones);
      }));
  }
  getDocumentosContrato(in_cont_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'contrato/getDocumentosContrato/' + in_cont_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let documentos = [];
          for (const item in resp['documentos']) {
            documentos.push({...resp['documentos'][item] });
          }
          return JSON.stringify(documentos);
      }));
  }
  crearModificacionContrato(postData : {
    in_cont_correlativo: number,
    in_nro_modificacion: number,
    iv_descripcion: string,
    in_domi_timo_correlativo: number,
    in_solpe_nro: number,
    in_domi_timod_correlativo: number,
    in_monto_modificacion: number,
    in_monto_actualizado: number,
    iv_fecha_modificacion: string,
    iv_fecha_inicio: string,
    iv_fecha_termino: string,
    in_admin_correlativo: number,
    iv_alcance: string,
    iv_arch_modificacion: string,
    iv_arch_solpe: string,
    iv_arch_general: string,
    iv_domi_correlativo_tipomodifica: string
  }) {
  //postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
  const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .post(
          this.urlService + 'contrato/crearModificacionContrato', postData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
          .pipe(
            map(resp => {
                let modificacion = [];
                for (const item in resp['contrato']) {
                  modificacion.push({...resp['modificacion'][item] });
                }
                return JSON.stringify(modificacion);
            }));

  }
  crearArchivoContrato(postData : {
            in_cont_correlativo: number,
            in_doc_correlativo: number,
            iv_nom_archivo: string,
            in_usua_correlativo: number
  }) {
    postData.in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'contrato/crearArchivoContrato', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let modificacion = [];
                  for (const item in resp['contrato']) {
                    modificacion.push({...resp['modificacion'][item] });
                  }
                  return JSON.stringify(modificacion);
              }));

  }
  updateModificacionContrato(postData : {
      in_moco_correlativo: number,
      in_cont_correlativo: number,
      in_nro_modificacion: number,
      iv_descripcion: string,
      in_domi_timo_correlativo: number,
      in_solpe_nro: number,
      in_domi_timod_correlativo: number,
      in_monto_modificacion: number,
      in_monto_actualizado: number,
      iv_fecha_modificacion: string,
      iv_fecha_inicio: string,
      iv_fecha_termino: string,
      in_admin_correlativo: number,
      iv_alcance: string,
      iv_arch_modificacion: string,
      iv_arch_solpe: string,
      iv_arch_general: string
    }) {
    //postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
        .post(
          this.urlService + 'contrato/updateModificacionContrato', postData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
          .pipe(
            map(resp => {
                let modificacion = [];
                for (const item in resp['modificacion']) {
                  modificacion.push({...resp['modificacion'][item] });
                }
                return JSON.stringify(modificacion);
            }));

  }
  deleteModificacionContrato(postData : {
    in_moco_correlativo: number,
    iv_vigente: string
  }) {
  //postData.in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
  const token = JSON.parse(localStorage.getItem('userData'))._token;
  return this.http
    .post(
      this.urlService + 'contrato/deleteModificacionContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
            let modificacion = [];
            for (const item in resp['contrato']) {
              modificacion.push({...resp['modificacion'][item] });
            }
            return JSON.stringify(modificacion);
    }));

  }
  getCargosContrato(iv_cargo: any) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getCargosContrato/' + iv_cargo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let cargos = [];
                for (const item in resp['cargos']) {
                  cargos.push({...resp['cargos'][item] });
                }
                return JSON.stringify(cargos);
            }));
  }
  getAdemsa() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getAdemsa', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let ademsa = [];
                for (const item in resp['ademsa']) {
                  ademsa.push({...resp['ademsa'][item] });
                }
                return JSON.stringify(ademsa);
            }));
  }
  crearCabeceraItemizado(postData : {
    in_subposicion: number,
    iv_descripcion: string,
    in_precio_unitario: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/crearCabeceraItemizado', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let cabecera = [];
              for (const item in resp['cabecera']) {
                cabecera.push({...resp['cabecera'][item] });
              }
              return JSON.stringify(cabecera);
          }));
  
  }
  relacionaContratoItemizado(postData : {
    in_cont_correlativo: number,
    in_item_correlativo: number
    in_usua_correlativo_creacion: number
  }) {
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/relacionaContratoItemizado', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let cabecera = [];
              for (const item in resp['cabecera']) {
                cabecera.push({...resp['cabecera'][item] });
              }
              return JSON.stringify(cabecera);
          }));
  
  }
  relacionaContratoItemizadoMod(postData : {
    in_cont_correlativo: number,
    in_item_correlativo: number,
    in_usua_correlativo_creacion: number,
    in_solpe_correaltivo: number
  }) {
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/relacionaContratoItemizadoMod', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let cabecera = [];
              for (const item in resp['cabecera']) {
                cabecera.push({...resp['cabecera'][item] });
              }
              return JSON.stringify(cabecera);
          }));
  
  }
  validaEtapaCorreoContrato(postData : {
    in_cont_correlativo: number,
    in_domi_correlativo_esdo_itemizado: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/validaEtapaCorreoContrato', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              console.log(resp['message']);
          }));
  
  }
  registraDetalleItemizado(postData : {
    in_item_correlativo: number,
    in_subposicion: number,
    iv_descripcion: string,
    iv_unidad: string,
    in_cantidad: number,
    in_precio_unitario: number,
    in_total: number,
    in_reajuste: number,
    in_scpq: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/registraDetalleItemizado', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let detalle = [];
              for (const item in resp['detalle']) {
                detalle.push({...resp['detalle'][item] });
              }
              return JSON.stringify(detalle);
          }));
  
  }
  getPeriodos(in_anio: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getPeriodos/' + in_anio, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let periodos = [];
                for (const item in resp['periodos']) {
                  periodos.push({...resp['periodos'][item] });
                }
                return JSON.stringify(periodos);
            }));
  }
  registraFechasContrato(postData : {
    in_peri_correlativo: number,
    in_dia_envio_ep_contratista: number,
    in_dia_aprob_ep_emsa: number,
    in_dia_recep_hes: number,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'contrato/registraFechasContrato', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let fechas = [];
              for (const item in resp['fechas']) {
                fechas.push({...resp['fechas'][item] });
              }
              return JSON.stringify(fechas);
          }));
  
  }
  getComboAdmc() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getComboAdmc', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let cargos = [];
                for (const item in resp['cargos']) {
                  cargos.push({...resp['cargos'][item] });
                }
                return JSON.stringify(cargos);
            }));
  }
  relacionaAdmcProveedor(postData : {
    in_proveedor_correlativo: number,
    in_usua_admc_correlativo: number,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'contrato/relacionaAdmcProveedor', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let admc = [];
              for (const item in resp['admc']) {
                admc.push({...resp['admc'][item] });
              }
              return JSON.stringify(admc);
          }));
  
  }
  getAdmcsProveedor(in_prov_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getAdmcsProveedor/' + in_prov_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let admc = [];
                for (const item in resp['admc']) {
                  admc.push({...resp['admc'][item] });
                }
                return JSON.stringify(admc);
            }));
  }
  getAdmcsProveedorSelect2(in_prov_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getAdmcsProveedorSelect2/' + in_prov_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let admc = [];
                for (const item in resp['admc']) {
                  admc.push({...resp['admc'][item] });
                }
                return JSON.stringify(admc);
            }));
  }
  deleteAdmcProveedor(postData : {
    in_usua_admc_correlativo: number,
    in_prov_correlativo: number,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'contrato/deleteAdmcProveedor', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let admc = [];
              for (const item in resp['admc']) {
                admc.push({...resp['admc'][item] });
              }
              return JSON.stringify(admc);
          }));
  
  }

  getAdecContrato(in_cont_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getAdecContrato/' + in_cont_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let adec = [];
                for (const item in resp['adec']) {
                  adec.push({...resp['adec'][item] });
                }
                return JSON.stringify(adec);
            }));
  }

  getAdecEmsaContrato(in_cont_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getAdecEmsaContrato/' + in_cont_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let adecEmsa = [];
                for (const item in resp['adecEmsa']) {
                  adecEmsa.push({...resp['adecEmsa'][item] });
                }
                return JSON.stringify(adecEmsa);
            }));
  }

  postAdecEmsaContrato(postData : {
    in_cont_correlativo :  number,
    in_admin_emsa :  number,
    in_usua_correlativo_creacion :  number
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'contrato/postAdecEmsaContrato', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let adecEmsaContrato = [];
                  for (const item in resp['adecEmsaContrato']) {
                    adecEmsaContrato.push({...resp['adecEmsaContrato'][item] });
                  }
                  return JSON.stringify(adecEmsaContrato);
              }));

  }
  getEPContrato(iv_tipo_ep: string , in_cont_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getEPContrato/' + iv_tipo_ep +  '/' + in_cont_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let ep_contrato = [];
                for (const item in resp['ep_contrato']) {
                  ep_contrato.push({...resp['ep_contrato'][item] });
                }
                return JSON.stringify(ep_contrato);
            }));
  }
  getSolpesCerradas() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getSolpesCerradas', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let solpes = [];
                for (const item in resp['solpes']) {
                  solpes.push({...resp['solpes'][item] });
                }
                return JSON.stringify(solpes);
            }));
  }

  getSolpeContrato(vv_solpe_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getSolpeContrato/' + vv_solpe_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let solpe = [];
                for (const item in resp['solpe']) {
                  solpe.push({...resp['solpe'][item] });
                }
                return JSON.stringify(solpe);
            }));
  }

  postEnviarCorreoInfoEmsaAplicacionModificacionContrato(
    postData : {
      pin_cont_correlativo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/postValidaExisteItemizadoContrato', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let itemizadoContrato = [];
              for (const item in resp['itemizadoContrato']) {
                itemizadoContrato.push({...resp['itemizadoContrato'][item] });
              }
              return JSON.stringify(itemizadoContrato);
          }));
  
  }

  deleteItemizadoContrato(pin_cont_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .delete(this.urlService + 'contrato/deleteEliminaRelacionContratoItemizado/' + pin_cont_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
      }));
  }

  postSolpePorCorrelativo(
    postData : {
      pin_solpe_correlativo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/postSolpesPorCorrelativo', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let solpe = [];
              for (const item in resp['solpe']) {
                solpe.push({...resp['solpe'][item] });
              }
              return JSON.stringify(solpe);
          }));
  
  }

  postSolpeModificacionContratoPorCorrelativo(
    postData : {
      pin_solpe_correlativo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/postSolpesModificacionContratoPorCorrelativo', postData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .pipe(
          map(resp => {
              let solpe = [];
              for (const item in resp['solpe']) {
                solpe.push({...resp['solpe'][item] });
              }
              return JSON.stringify(solpe);
          }));
  
  }

  getEPDistribucionV1(iv_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getEPDistribucionV1/' + iv_ep_correlativo, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let distribucion = [];
                for (const item in resp['distribucion']) {
                  distribucion.push({...resp['distribucion'][item] });
                }
                return JSON.stringify(distribucion);
            }));
  }

  postUsuariosEmailCreacionContrato(){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .post(this.urlService + 'contrato/postUsuariosEmailCreacionContrato', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let usuariosEmailContrato = [];
          for (const item in resp['usuariosEmailCreacionContrao']) {
            usuariosEmailContrato.push({...resp['usuariosEmailCreacionContrao'][item] });
          }
          return JSON.stringify(usuariosEmailContrato);
      }));
  }

  putPublicarContrato(
    postData : {
      pin_contrato_correlativo: number
    }
  ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .put(this.urlService + 'contrato/putPublicarContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let mensaje = {};
          mensaje = {...resp['message'] };
          return JSON.stringify(mensaje);
      }));
  }

  postEstadoItemizadoContrato(
    postData : {
      pin_contrato_correlativo: number
    }
  ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .post(this.urlService + 'contrato/postEstadoItemizadoContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let estadoItemizadoContrato = [];
          for (const item in resp['estadoItemizado']) {
            estadoItemizadoContrato.push({...resp['estadoItemizado'][item] });
          }
          return JSON.stringify(estadoItemizadoContrato);
      }));
  }

  getContratosExportarPorfecha(
    iv_es_cont_interno: string, 
    idRol: number, 
    vDesde: any, 
    vHasta: any
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'contrato/getContratosExportarPorfecha/' + iv_es_cont_interno + '/' + idRol + '/' + vDesde + '/' + vHasta, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let contratos = [];
                for (const item in resp['contratos']) {
                  contratos.push({...resp['contratos'][item] });
                }
                return JSON.stringify(contratos);
            }));
  }

  postFiniquitoContrato(
    postData: {
      correlativoContrato: number,
      correlativoEstado: number,
      observacion: string
    }
  ) {
    return this.http
      .post(
        this.urlService + 'contrato/postFiniquitoContrato', postData)
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }

  updateContratoRequiereDocGarantia(
    postData: {
      requiereDocGarantia: string;
      correlativoContrato: number;
    }
  ) {
    return this.http
      .post(
        this.urlService + 'contrato/updateContratoRequiereDocGarantia', postData)
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }

  getSelectManualesProcedimientos() {
    return this.http
        .get(this.urlService + 'contrato/getSelectManualesProcedimientos')
        .pipe(
            map(resp => {
                let manualesProcedimientos = [];
                for (const item in resp['manualesProcedimientos']) {
                  manualesProcedimientos.push({...resp['manualesProcedimientos'][item] });
                }
                return JSON.stringify(manualesProcedimientos);
            }));
  }

  getReporteDistribicionesEp(){
    return this.http.get( `${this.urlService}contrato/getReporteDistribicionesEp`, {responseType:'blob'});
  }

  getSelectReporteContratos(){
    return this.http.get( `${this.urlService}contrato/getSelectReporteContratos`, {responseType:'blob'});
  }

  getSelectItemizados(){
    return this.http.get( `${this.urlService}contrato/getSelectItemizados`, {responseType:'blob'});
  }

  getSelectItemizadosModCont(){
    return this.http.get( `${this.urlService}contrato/getSelectItemizadosModCont`, {responseType:'blob'});
  }

  getSelectAccesosAReportes() {
    return this.http
        .get(this.urlService + 'contrato/getSelectAccesosAReportes')
        .pipe(
            map(resp => {
                let accesosAReportes = [];
                for (const item in resp['accesosAReportes']) {
                  accesosAReportes.push({...resp['accesosAReportes'][item] });
                }
                return JSON.stringify(accesosAReportes);
            }));
  }

  postValidateCodigoContrato(
    postData: {
      codigoContrato: string;
    }
  ) {
    return this.http
      .post(
         `${this.urlService}contrato/postValidateCodigoContrato`, postData)
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }
}
