import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { AuditoresService } from "../shared/services/auditores.service";

interface AuditoresResponse {
  message: string;
  auditores: Auditores[];
}

interface Auditores {
  usuario_correlativo: number;
  nombre: string;
  email: string;
  vigente: string;
  contratosRelacionados: ContratosRelacionado[];
}

interface ContratosRelacionado {
  correlativo_contrato: number;
  correlativo_usuario: number;
}

interface ContratosCompleteResponse {
  message: string;
  contratosComplete: ContratosComplete[];
}

interface ContratosComplete {
  correlativo: number;
  codigo_contrato: string;
  nombre_contrato: string;
  estado_contrato_correlativo: number;
  estado_contrato: EstadoContrato;
  checked: boolean;
}

enum EstadoContrato {
  Cancelado = "Cancelado",
  Expirado = "Expirado",
  Finiquitado = "Finiquitado",
  Vigente = "Vigente",
}

@Component({
  selector: "app-mantenedor-auditor-interno",
  templateUrl: "./mantenedor-auditor-interno.component.html",
  styleUrls: ["./mantenedor-auditor-interno.component.css"],
})
export class MantenedorAuditorInternoComponent implements OnInit {
  userId: number;
  rolId: number;
  auditores: Auditores[];
  auditoresFiltered: Auditores[];
  contratos: ContratosComplete[];
  contratosFiltered: ContratosComplete[];
  contContratos: number;
  contContratosRelacionados: number;
  auditorSeleccionado: number;
  defaultChecked: boolean;
  toggleActionStr: string;
  isSectionToggleAll: boolean;

  constructor(
    private notifyService: NotificationService,
    private auditorService: AuditoresService
  ) {
    this.auditores = [];
    this.contratos = [];
    this.contContratos = 0;
    this.contContratosRelacionados = 0;
    this.defaultChecked = false;
    this.toggleActionStr = "";
    this.isSectionToggleAll = false;
  }

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem("userData")).userId;
    this.rolId = JSON.parse(localStorage.getItem("userData")).rolId;

    this.getAuditores();
  }

  getAuditores() {
    this.auditorService.getSelectAuditores().subscribe(
      (resp) => {
        const auditorResponse: AuditoresResponse = resp;

        this.auditores = auditorResponse.auditores;
        this.auditoresFiltered = auditorResponse.auditores;

        if (this.auditorSeleccionado !== 0) {
          const auditorSeleccionado: Auditores = this.auditores.find(
            (auditor) =>
              auditor.usuario_correlativo === this.auditorSeleccionado
          );
          this.editarContratosAuditor(
            auditorSeleccionado.contratosRelacionados,
            this.auditorSeleccionado
          );
        }
      },
      (error) => {
        console.log(error);
        this.notifyService.showError(
          error.error.message,
          "Error al obtener los auditores"
        );
      }
    );
  }

  applyFilterAuditores(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (filterValue != "") {
      this.auditoresFiltered = this.auditores.filter((data) => {
        return (
          data?.usuario_correlativo
            .toString()
            .toLowerCase()
            .includes(filterValue) ||
          data?.nombre.toString().toLowerCase().includes(filterValue) ||
          data?.email.toString().toLowerCase().includes(filterValue)
        );
      });
    } else {
      this.auditoresFiltered = this.auditores;
    }
  }

  applyFilterContratos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (filterValue != "") {
      this.contratosFiltered = this.contratos.filter((data) => {
        return (
          data?.correlativo.toString().toLowerCase().includes(filterValue) ||
          data?.codigo_contrato
            .toString()
            .toLowerCase()
            .includes(filterValue) ||
          data?.nombre_contrato.toString().toLowerCase().includes(filterValue)
        );
      });
    } else {
      this.contratosFiltered = this.contratos;
    }
  }

  editarContratosAuditor(
    contratosRelacionados: ContratosRelacionado[],
    idAuditor: number
  ) {
    this.defaultChecked = false;
    this.auditorService.getSelectContratosComplete().subscribe(
      (resp) => {
        const contratosComplete: ContratosCompleteResponse = resp;

        this.auditorSeleccionado = idAuditor;
        this.contratos = [];
        this.contratosFiltered = [];

        for (
          let index = 0;
          index < contratosComplete.contratosComplete.length;
          index++
        ) {
          const element = contratosComplete.contratosComplete[index];

          const itemContrato: ContratosComplete = {
            correlativo: element.correlativo,
            codigo_contrato: element.codigo_contrato,
            nombre_contrato: element.nombre_contrato,
            estado_contrato_correlativo: element.estado_contrato_correlativo,
            estado_contrato: element.estado_contrato,
            checked: contratosRelacionados.some(
              (contrato) =>
                contrato.correlativo_contrato === element.correlativo
            ),
          };

          this.contratos.push(itemContrato);
        }

        this.contratosFiltered = this.contratos;
        this.contContratos = this.contratos.length;
        this.contContratosRelacionados = contratosRelacionados.length;

        if (this.contratos.length === contratosRelacionados.length) {
          this.defaultChecked = true;
        }
      },
      (error) => {
        this.contratos = [];
        this.contratosFiltered = [];
        this.contContratos = 0;
        this.contContratosRelacionados = 0;
        console.log(error);
        this.notifyService.showError(
          error.error.message,
          "Error al obtener los contratos"
        );
      }
    );
  }

  toggleContrato(correlativoContrato: number, event: any) {
    this.auditorService
      .postEditarContratoAsociadoAuditor({
        idContrato: correlativoContrato,
        idAuditor: this.auditorSeleccionado,
        accion: event.target.checked,
      })
      .subscribe((resp) => {
        if (event.target.checked) {
          this.notifyService.showSuccess(
            "Contrato asociado al auditor",
            "Contrato Asociado"
          );
        } else {
          this.notifyService.showWarning(
            "Contrato desasociado al auditor",
            "Contrato Desasociado"
          );
        }

        this.getAuditores();
      });

    console.log(
      correlativoContrato,
      event.target.checked,
      this.auditorSeleccionado
    );
  }

  toggleAll(checked: boolean) {
    this.defaultChecked = checked;

    if (checked) {
      this.toggleActionStr = "¿Desea asociar todos los contratos al auditor?";
    } else {
      this.toggleActionStr =
        "¿Desea desasociar todos los contratos al auditor?";
    }

    this.isSectionToggleAll = true;
  }

  onToggleAll() {
    if (this.defaultChecked) {
      this.auditorService
        .postInsertAsignarTodosContratosAuditor({
          idAuditor: this.auditorSeleccionado,
        })
        .subscribe(
          (resp) => {
            for (
              let index = 0;
              index < this.contratosFiltered.length;
              index++
            ) {
              this.contratosFiltered[index].checked = this.defaultChecked;
            }

            this.getAuditores();

            this.isSectionToggleAll = false;

            this.notifyService.showSuccess(
              "Todos los contratos fueron asociados al auditor",
              "Contratos Asociados"
            );
          },
          (err) => {
            console.log(err);
            this.notifyService.showError(
              err.error.message,
              "Error al asociar contratos"
            );
          }
        );
    } else {
      this.auditorService
        .postDeleteQuitarTodosContratosAuditor({
          idAuditor: this.auditorSeleccionado,
        })
        .subscribe(
          (resp) => {
            for (
              let index = 0;
              index < this.contratosFiltered.length;
              index++
            ) {
              this.contratosFiltered[index].checked = this.defaultChecked;
            }

            this.getAuditores();

            this.isSectionToggleAll = false;

            this.notifyService.showWarning(
              "Todos los contratos fueron desasociados al auditor",
              "Contratos Asociados"
            );
          },
          (err) => {
            console.log(err);
            this.notifyService.showError(
              err.error.message,
              "Error al asociar contratos"
            );
          }
        );
    }
  }

  cancelarToggleAll() {
    this.isSectionToggleAll = false;
    this.defaultChecked = !this.defaultChecked;
  }
}
