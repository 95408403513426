<!-- begin #header -->
<div id="header" class="header navbar-default" *ngIf="isAuth">
    <!-- begin navbar-header -->
    <div class="navbar-header">
        <a routerLink="/home" class="navbar-brand">
            <img src="../assets/img/logo/logo-emsa.jpg" class="width: 100px; height: 30px;">
            <b>Exploraciones Mineras</b>
        </a>
        <button type="button" class="navbar-toggle" data-click="sidebar-toggled">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
    </div>
    <!-- end navbar-header -->
    <!-- begin header-nav -->
    <ul class="navbar-nav navbar-right">
        <li class="dropdown navbar-user">
            <a style="text-align: right;" href="#" class="dropdown-toggle" data-toggle="dropdown">
                <!--<img src="../assets/img/user/user-13.jpg" alt="" />-->
                <span class="d-none d-md-inline">
                    <span>{{userData.userName}}<b class="caret"></b></span>
                    <br>
                    <span class="text-muted">{{lastConectionUser | date: 'dd/MM/yyy HH:mm:ss'}}</span>
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <a *ngFor="let item of cargos" style="cursor: pointer; font-weight: normal;"
                    (click)="cambiarCargo(item.ROL_CORRELATIVO, item.GLOSA_ROL)" class="dropdown-item"
                    [ngClass]="{' text-info' : nombreCargoActual == item.GLOSA_ROL}">
                    {{ item.GLOSA_ROL }}
                </a>
                <div class="dropdown-divider"></div>
                <a style="cursor: pointer; font-weight: normal;" (click)="modalChangePassword.openModal()"
                    class="dropdown-item">Cambiar contraseña</a>
                <a style="cursor: pointer; font-weight: normal;" (click)="onLogout()" class="dropdown-item">Salir</a>
            </div>
        </li>
    </ul>
    <!-- end header-nav -->
</div>
<!-- end #header -->

<!-- begin #sidebar -->
<div id="sidebar" class="sidebar" *ngIf="isAuth">
    <!-- begin sidebar scrollbar -->
    <div data-scrollbar="true" data-height="100%">
        <!-- begin sidebar user -->
        <ul class="nav">
            <li class="nav-profile">
                <a href="javascript:;" data-toggle="nav-profile">
                    <div class="cover with-shadow"></div>
                    <!--<div class="image">
                        <img src="../assets/img/user/user-13.jpg" alt="" />
                    </div>-->
                    <div class="info">
                        <b class="caret pull-right"></b>{{ nombreCargoActual }}
                        <small></small>
                    </div>
                </a>
            </li>
        </ul>
        <!-- end sidebar user -->
        <!-- begin sidebar nav -->
        <ul class="nav">
            <li class="nav-header">Menu</li>
            <!--<li>
              <a routerLink="/por-autorizar">
                  <i class="fab fa-simplybuilt"></i>
                  <span>Por Autorizar</span>
              </a>
            </li>-->
            <li class="has-sub" *ngIf="(existeModulo(2) && existeModuloCargo(2))">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-file-contract"></i>
                    <span>Contratos</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/contratos">Contratos</a></li>
                    <li *ngIf="idCargoActual != 4 && idCargoActual != 5"><a routerLink="/dashboard-itemizado">Itemizado Original</a></li>
                    <li *ngIf="idCargoActual != 4 && idCargoActual != 5"><a routerLink="/dashboard-itemizado-mod">Itemizado Modificación de Contrato</a></li>
                    <li *ngIf="idCargoActual == 17 || idCargoActual == 20"><a routerLink="/periodos-contratos">Periodos
                            Contratos</a></li>
                </ul>
            </li>
            <li class="has-sub" *ngIf="(existeModulo(3) && existeModuloCargo(3))">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-money-bill-wave-alt"></i>
                    <span>Estados de Pago</span>
                </a>
                <!--<ul class="sub-menu">
                    <li><a routerLink="/create-contrato">Crear contrato</a></li>
                </ul>-->
                <ul class="sub-menu">
                    <li><a routerLink="/estados-pago">Estados de Pago</a></li>
                    <li *ngIf="idCargoActual === 2 || idCargoActual === 17 || idCargoActual === 22"><a
                            routerLink="/estados-pago-incurrido">Estados de Pago Incurrido</a></li>
                    <li *ngIf="idCargoActual === 1"><a routerLink="/checklist">Checklist</a></li>
                </ul>
            </li>
            <!-- <li class="has-sub" *ngIf="existeModulo('CORREOS')">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Correos</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/email">Mantención</a></li>
                    <li><a routerLink="/view-emails">Enviados</a></li>
                </ul>
            </li> -->

            <li *ngIf="(existeModulo(4) && existeModuloCargo(4))">
                <a *ngIf="idCargoActual === 1 || idCargoActual === 2 || idCargoActual === 3 || idCargoActual === 4 || idCargoActual === 5" routerLink="/boletas">
                    <i class="fas fa-money-check-alt"></i>
                    <span>Documentos de Garantía</span>
                </a>
            </li>
            <li class="has-sub" *ngIf="(existeModulo(1) && existeModuloCargo(1))">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Solpe</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/solpe/0">Todas</a></li>
                    <li><a routerLink="/solpe/61">Compras</a></li>
                    <li><a routerLink="/solpe/62">Servicios</a></li>
                    <li><a routerLink="/solpe/63">Servicios Profesionales</a></li>
                    <li><a routerLink="/solpe/60">Arriendo</a></li>
                    <li><a routerLink="/solpe/82">Modificacion de contrato</a></li>
                </ul>
            </li>
            <li *ngIf="idCargoActual === 1" class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-cogs"></i>
                    <span>Mantenedores</span>
                </a>
                <ul class="sub-menu">
                    <!-- <li><a routerLink="/mantenedores-solpe">Mantenedores</a></li> -->
                    <li><a routerLink="/proveedores">Proveedores</a></li>
                    <li><a routerLink="/mantenedor-items">Mantenedor Checklist</a></li>
                    <li><a routerLink="/parametros">Parametros</a></li>
                </ul>
            </li>
            <li class="has-sub" *ngIf="(existeModulo(7) && existeModuloCargo(7))">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-address-card"></i>
                    <span>Usuarios</span>
                </a>
                <ul class="sub-menu">
                    <!--<li><a routerLink="/roles">Funcionalidad</a></li>-->
                    <li><a routerLink="/usuarios">Usuarios</a></li>
                    <li><a routerLink="/acciones">Acciones</a></li>
                    <li><a routerLink="/usuarios-funcionalidad">Usuarios-Funcionalidad</a></li>
                    <li><a routerLink="/usuarios-roles">Usuarios-Roles</a></li>
                    <li><a routerLink="/mantenedor-auditor-interno">Auditores Internos</a></li>
                </ul>
            </li>
            <li class="has-sub" *ngIf="(existeModulo(9) && existeModuloCargo(9))">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fas fa-list"></i>
                    <span>Auditoria</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/logs">Logs</a></li>
                    <li *ngIf="idCargoActual == 1" class="cursor-pointer"><a (click)="generarReporteDistEp()">Provisión EP</a></li>
                </ul>
            </li>
            <li class="has-sub" *ngIf="(existeModulo(11) && existeModuloCargo(11))">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-cogs"></i>
                    <span>Módulo P&C</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/mantenedores-solpe">Mantenedores</a></li>
                    <ng-container *ngIf="validateAccesoAReportePyC()">
                        <li><a href="javascript:void(0);" (click)="getReporteDistribicionesEp()">Distribución EP</a></li>
                        <li><a href="javascript:void(0);" (click)="getSelectReporteContratos()">Contratos</a></li>
                        <li><a href="javascript:void(0);" (click)="getSelectItemizados()">Itemizados Original Contratos</a></li>
                        <li><a href="javascript:void(0);" (click)="getSelectItemizadosModCont()">Itemizados Mod. Contratos</a></li>
                    </ng-container>
                </ul>
            </li>
            <li *ngIf="(existeModulo(12) && existeModuloCargo(12))" class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-cogs"></i>
                    <span>Mantenedor Proveedores</span>
                </a>
                <ul class="sub-menu">
                    <!-- <li><a routerLink="/mantenedores-solpe">Mantenedores</a></li> -->
                    <li><a routerLink="/proveedores">Proveedores</a></li>
                </ul>
            </li>
            <!-- *ngIf="esEmsa" -->
            <li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <!-- <i class="fa fa-th-large"></i> -->
                    <i class="fas fa-address-card"></i>
                    <span>Ayuda</span>
                </a>
                <ul class="sub-menu">
                    <!-- <li><a routerLink="/help">Manuales</a></li> -->
                    <li><a routerLink="/manuales-procedimientos">Manuales</a></li>
                </ul>
            </li>
            <!--
            <li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Boletas de Garantía</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/boletas">Ver Boletas Garantía</a></li>
                    <li><a routerLink="/create-boleta">Agregar Documento</a></li>
                </ul>
            </li>-->
            <!--<li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Boletas</span>
                </a>
            </li>
            <li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Seguimiento</span>
                </a>
            </li>
            <li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Mantención</span>
                </a>
            </li>
            <li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>SOLPE</span>
                </a>
            </li>
            <li class="has-sub">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-th-large"></i>
                    <span>Viajes</span>
                </a>
            </li>-->

            <!-- begin sidebar minify button -->
            <li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i
                        class="fa fa-angle-double-left"></i></a></li>
            <!-- end sidebar minify button -->
        </ul>
        <!-- end sidebar nav -->
    </div>
    <!-- end sidebar scrollbar -->
</div>
<div class="sidebar-bg" *ngIf="isAuth"></div>
<!-- end #sidebar -->

<app-modal-change-password #modalChangePassword></app-modal-change-password>

<app-modal-reload-session [isAuth]="isAuth"></app-modal-reload-session>

<!-- Modal -->
<div class="modal fade" id="modalProximamente" tabindex="-1" role="dialog" aria-labelledby="modalProximamenteLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="modalProximamenteLabel">Próximamente</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p style="font-size: 14px;">
            Esta característica se encuentra en revisión
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Aceptar</button>
    </div>
    </div>
</div>
</div>